import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

import {
  AdjustmentsHorizontalIcon,
  CalendarDaysIcon,
  CircleStackIcon,
  Cog6ToothIcon,
} from "@heroicons/react/24/outline";

import { HeroIcon } from "../../../../../models/primitives";
import SideMenu from "../../../components/sidemenu/SideMenu";
import SideMenuEntry from "../../../components/sidemenu/SideMenuEntry";
import { InfluencingFactorDetail } from "../../../models/influencingFactor";
import {
  InfluencingFactorPage,
  InfluencingFactorPageZod,
} from "../../../routes/influencingFactors";

interface FactorMenuProps {
  influencingFactor: InfluencingFactorDetail | undefined;
}

export default function FactorMenu(props: FactorMenuProps): JSX.Element {
  const { influencingFactor } = props;
  const pages = usePages(influencingFactor);
  const page = useCurrentPage();

  return (
    <SideMenu>
      {pages.map((item) => (
        <SideMenuEntry
          key={item.id}
          id={item.id}
          name={item.name}
          href={`./${item.id}`}
          icon={item.icon}
          active={item.id === page}
          disabled={item.disabled}
        />
      ))}
    </SideMenu>
  );
}

interface NavItem {
  id: InfluencingFactorPage;
  name: string;
  icon: HeroIcon;
  disabled?: boolean;
}

const usePages = (factor: InfluencingFactorDetail | undefined): NavItem[] => {
  const { t } = useTranslation();

  return [
    { id: "general", name: t("General Settings"), icon: Cog6ToothIcon },
    {
      id: "ranges",
      name: t("Ranges"),
      icon: CalendarDaysIcon,
    },
    {
      id: "models",
      name: t("Models"),
      icon: AdjustmentsHorizontalIcon,
      disabled: !factor || factor.appliesToSolution,
    },
    {
      id: "dataSegments",
      name: t("Observables & Planning Areas"),
      icon: CircleStackIcon,
      disabled: !factor || factor.appliesToSolution,
    },
  ];
};

export const useCurrentPage = (): InfluencingFactorPage => {
  const location = useLocation();
  const paths = location.pathname.split("/");

  return InfluencingFactorPageZod.catch("general").parse(
    paths[paths.length - 1]
  );
};
