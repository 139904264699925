import { lazy } from "react";

const STORAGE_KEY = "refreshed-by-lazyWithRetry";

// source:
// https://gist.github.com/raphael-leger/4d703dea6c845788ff9eb36142374bdb

const lazyWithRetry: typeof lazy = (componentImport) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem(STORAGE_KEY) || "false"
    );

    try {
      const component = await componentImport();

      window.localStorage.setItem(STORAGE_KEY, "false");

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        window.localStorage.setItem(STORAGE_KEY, "true");
        window.location.reload();
      }

      throw error;
    }
  });

export default lazyWithRetry;
