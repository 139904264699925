import { Task, runningNonCanceledTasks } from "../models/task";

export function getTasksProgress(
  tasks: Pick<Task, "progress">[]
): number | undefined {
  const values = tasks
    .map((task) => task.progress)
    .filter((p: number | undefined): p is number => typeof p === "number");

  if (values.length === 0) {
    return undefined;
  }

  return Math.min(...values);
}

export function getTaskDuration(
  task: Pick<Task, "startedTime" | "finishedTime" | "status">,
  currentDate?: Date
): string | undefined {
  const { startedTime, finishedTime } = task;
  const finishedOrNow = finishedTime ? finishedTime : currentDate;
  if (!startedTime || !finishedOrNow) {
    return undefined;
  }
  if (startedTime > finishedOrNow) {
    return undefined;
  }

  if (!finishedTime && !runningNonCanceledTasks.includes(task.status)) {
    return undefined;
  }

  let delta = Math.round(
    (finishedOrNow.valueOf() - startedTime.valueOf()) / 1000
  );

  const days = Math.floor(delta / (24 * 3600));
  delta -= days * 24 * 3600;

  const hours = Math.floor(delta / 3600);
  delta -= hours * 3600;

  const minutes = Math.floor(delta / 60);
  delta -= minutes * 60;

  const seconds = delta;

  let result = `${seconds}s`;
  if (minutes || hours || days) {
    result = `${minutes}m ${result}`;
  }
  if (hours || days) {
    result = `${hours}h ${result}`;
  }
  if (days) {
    result = `${days}d ${result}`;
  }

  return result;
}
