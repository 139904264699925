import React from "react";
import { RouteObject } from "react-router";

import lazyWithRetry from "../../../services/lazyWithRetry";
import AnalysisMinScale from "../components/AnalysisMinScale";
import { getAnalysisPath } from "./analyzer";

const WeeklyProfiles = lazyWithRetry(
  () => import("../pages/WeeklyProfiles/WeeklyProfiles")
);

const WEEKLY_PROFILES_PATH = "weeklyProfiles";

export const weeklyProfilesRoute: RouteObject = {
  path: WEEKLY_PROFILES_PATH,
  element: (
    <AnalysisMinScale scaleTooHigh="1D">
      <WeeklyProfiles />
    </AnalysisMinScale>
  ),
};

export function getWeeklyProfilesPath(
  solutionId: string | number,
  analysisId: string | number
): string {
  return `${getAnalysisPath(solutionId, analysisId)}/${WEEKLY_PROFILES_PATH}`;
}
