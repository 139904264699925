import React from "react";

import classNames from "classnames";

import SkeletonCard from "../../../../../components/skeletons/SkeletonCard";
import SkeletonHero from "../../../../../components/skeletons/SkeletonHero";
import SkeletonWrapper from "../../../../../components/skeletons/SkeletonWrapper";

export default function ProjectDashboardSkeleton(): JSX.Element {
  return (
    <div className="space-y-4">
      <div>
        <SkeletonWrapper noLoading>
          <SkeletonHero />
        </SkeletonWrapper>
        <div className="grid gap-4 grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3">
          <div className="flex flex-col space-y-4 lg:col-span-2">
            <div className="grid grid-cols-3 lg:grid-cols-6 gap-4">
              <MetricSkeleton />
              <MetricSkeleton />
              <MetricSkeleton />
              <MetricSkeleton />
              <MetricSkeleton />
              <MetricSkeleton />
            </div>
            <SkeletonWrapper spinnerSize={24}>
              <SkeletonCard style={{ height: 300 }}></SkeletonCard>
            </SkeletonWrapper>
          </div>
          <CardSkeleton />
          <CardSkeleton />
          <CardSkeleton className="lg:col-span-2" />
        </div>
      </div>
    </div>
  );
}

function MetricSkeleton(): JSX.Element {
  return (
    <SkeletonWrapper spinnerSize={24}>
      <SkeletonCard style={{ height: 92 }} />
    </SkeletonWrapper>
  );
}

interface CardSkeletonProps {
  className?: string;
}

function CardSkeleton(props: CardSkeletonProps): JSX.Element {
  const { className } = props;
  return (
    <SkeletonWrapper spinnerSize={24} className={className}>
      <SkeletonCard
        style={{ height: undefined, minHeight: 400 }}
        className={classNames("h-full")}
      />
    </SkeletonWrapper>
  );
}
