import React from "react";

import classNames from "classnames";

interface TableProps {
  children?: React.ReactNode;
  id?: string;
  className?: string;
  disableOverflowX?: boolean;
  style?: React.CSSProperties;
  bordered?: boolean;
}

export default function Table(props: TableProps) {
  const { id, children, style, className = "" } = props;
  const { disableOverflowX, bordered = false } = props;
  return (
    <div
      id={id}
      className={classNames(
        "w-full align-middle",
        !disableOverflowX ? "overflow-x-auto" : "",
        className
      )}
      style={style}
    >
      <table className="min-w-full divide-y divide-gray-300">
        <TableContext.Provider value={{ bordered }}>
          {children}
        </TableContext.Provider>
      </table>
    </div>
  );
}

type TableContextProps = {
  bordered: boolean;
};

const TableContext = React.createContext<TableContextProps>({
  bordered: false,
});

export function useTableContext(): TableContextProps {
  return React.useContext(TableContext);
}
