import React from "react";

import classNames from "classnames";

import Form from "./Form";

export const MAX_NF_WIDTH = "sm:max-w-2xl";

interface NarrowFormProps {
  children: React.ReactNode;
  onSubmit?(): void;
  withMenu?: boolean;
  noWidthLimit?: boolean;
  formClassName?: string;
}

export default function NarrowForm(props: NarrowFormProps) {
  const { children, withMenu, noWidthLimit, formClassName, onSubmit } = props;

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (onSubmit) {
      onSubmit();
    }
  };

  return (
    <div className={!withMenu ? "w-full flex justify-center" : ""}>
      <Form
        className={classNames(
          "w-full",
          !noWidthLimit ? MAX_NF_WIDTH : "",
          formClassName
        )}
        onSubmit={handleSubmit}
      >
        {children}
      </Form>
    </div>
  );
}
