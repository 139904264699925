import React from "react";

import { ButtonSize, ButtonVariant, getButtonStyle } from ".";

export interface ButtonProps
  extends Omit<
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    "ref"
  > {
  variant?: ButtonVariant;
  size?: ButtonSize;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref): JSX.Element => {
    const {
      variant = "primary",
      size = "medium",
      className,
      children,
      ...rest
    } = props;

    return (
      <button
        ref={ref}
        {...rest}
        className={getButtonStyle(variant, size, className)}
      >
        {children}
      </button>
    );
  }
);

export default Button;
