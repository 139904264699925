import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";

import classNames from "classnames";

import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

import ConditionalWrapper from "./ConditionalWrapper";
import PaginationLabel from "./PaginationLabel";
import Button from "./buttons/Button";

interface PaginationProps {
  id: string;
  page: number;
  pageSize: number;
  totalCount: number;
  noBorder?: boolean;
  size?: "medium" | "small";
  queryKey?: string;
}

export default function Pagination(props: PaginationProps) {
  const { id, page, pageSize, totalCount, noBorder, size = "medium" } = props;
  const { queryKey = "Page" } = props;

  const totalPagesCount = Math.ceil(totalCount / pageSize);

  const realPage = Math.min(Math.max(1, page), totalPagesCount);

  const firstDisplayed = (realPage - 1) * pageSize + 1;
  const lastDisplayed = firstDisplayed + pageSize - 1;
  const realLastDisplayed =
    lastDisplayed > totalCount ? totalCount : lastDisplayed;

  const visiblePages = getVisiblePages(totalPagesCount, realPage);

  const { t } = useTranslation();

  let prevLink: string | null = null;
  let nextLink: string | null = null;
  const [searchParams] = useSearchParams();
  if (realPage > 1) {
    searchParams.set(queryKey, (realPage - 1).toString());
    prevLink = `?${searchParams.toString()}`;
  }
  if (realPage < totalCount) {
    searchParams.set(queryKey, (realPage + 1).toString());
    nextLink = `?${searchParams.toString()}`;
  }

  return (
    <div
      className={classNames(
        "flex items-center justify-between  bg-white",
        !noBorder ? "border-t border-gray-200 pl-5 sm:pl-1 pr-2 py-3" : ""
      )}
    >
      <div className="flex flex-1 justify-between items-center sm:hidden pt-2">
        <ConditionalWrapper
          condition={!!prevLink}
          wrapper={(children) => (
            <Link to={prevLink ?? ""} className="no-underline">
              {children}
            </Link>
          )}
        >
          <Button id={`${id}-prevButton`} variant="white">
            <span className="sr-only">{t("Previous")}</span>
            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
          </Button>
        </ConditionalWrapper>
        <span className="text-sm">
          {t("Page")} {realPage}/{totalPagesCount}
        </span>
        <ConditionalWrapper
          condition={!!nextLink}
          wrapper={(children) => (
            <Link to={nextLink ?? ""} className="no-underline">
              {children}
            </Link>
          )}
        >
          <Button id={`${id}-nextButton`} variant="white">
            <span className="sr-only">{t("Next")}</span>
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </Button>
        </ConditionalWrapper>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between space-x-4">
        <div>
          <PaginationLabel
            from={firstDisplayed}
            to={realLastDisplayed}
            total={totalCount}
            className={classNames(
              size === "medium" ? "text-sm" : "",
              size === "small" ? "text-xs" : ""
            )}
          />
        </div>
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm text-gray-900"
            aria-label="Pagination"
          >
            <PaginationButton
              className="rounded-l-md !px-2 text-gray-400"
              page={realPage > 1 ? realPage - 1 : undefined}
              size={size}
              queryKey={queryKey}
            >
              <span className="sr-only">{t("Previous")}</span>
              <ChevronLeftIcon
                className={classNames(
                  size === "medium" ? "h-5 w-5" : "",
                  size === "small" ? "h-3 w-3 my-0.5" : ""
                )}
                aria-hidden="true"
              />
            </PaginationButton>
            {visiblePages.map((group, index) => (
              <React.Fragment key={index}>
                {index > 0 && (
                  <PaginationButton
                    className="text-gray-400"
                    size={size}
                    queryKey={queryKey}
                  >
                    ...
                  </PaginationButton>
                )}
                {group.map((page) => (
                  <PaginationButton
                    key={page}
                    page={page}
                    selected={page === realPage}
                    size={size}
                    queryKey={queryKey}
                  >
                    {page}
                  </PaginationButton>
                ))}
              </React.Fragment>
            ))}
            <PaginationButton
              className="rounded-r-md !px-2 text-gray-400"
              page={realPage < totalPagesCount ? realPage + 1 : undefined}
              size={size}
              queryKey={queryKey}
            >
              <span className="sr-only">{t("Next")}</span>
              <ChevronRightIcon
                className={classNames(
                  size === "medium" ? "h-5 w-5" : "",
                  size === "small" ? "h-3 w-3 my-0.5" : ""
                )}
                aria-hidden="true"
              />
            </PaginationButton>
          </nav>
        </div>
      </div>
    </div>
  );
}

interface PaginationButtonProps {
  children: React.ReactNode;
  page?: number;
  selected?: boolean;
  className?: string;
  size?: "medium" | "small";
  queryKey: string;
}

const UNSELECTED_STYLE = "focus:z-20 focus:outline-offset-0";
const SELECTED_STYLE =
  "z-10 bg-blue-100 text-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600";

const PaginationButton = (props: PaginationButtonProps): JSX.Element => {
  const { children, page, selected, queryKey } = props;
  const { className = "", size = "medium" } = props;

  const [searchParams] = useSearchParams();
  if (page) {
    searchParams.set(queryKey, page.toString());
  }

  return (
    <ConditionalWrapper
      condition={!!page}
      wrapper={(children) => (
        <Link
          to={`?${searchParams.toString()}`}
          className="no-underline text-gray-900"
        >
          {children}
        </Link>
      )}
    >
      <div
        className={classNames(
          "relative inline-flex items-center font-semibold ring-1 ring-inset ring-gray-300",
          size === "medium" ? "px-4 py-2 text-sm" : "",
          size === "small" ? "px-2.5 py-1.5 text-xs" : "",
          selected ? SELECTED_STYLE : UNSELECTED_STYLE,
          page ? (selected ? "hover:bg-blue-50" : "hover:bg-gray-50") : "",
          className
        )}
      >
        {children}
      </div>
    </ConditionalWrapper>
  );
};

const getVisiblePages = (count: number, current: number): number[][] => {
  // always display all pages if there's 7 or fewer of them
  // 7 is the minimum buttons count
  if (count <= 7) {
    return [Array.from(Array(count + 1).keys()).slice(1)];
  }

  if (current <= 2 || current >= count - 1) {
    return [
      [1, 2, 3],
      [count - 2, count - 1, count],
    ];
  }

  if (current === 3) {
    return [
      [1, 2, 3, 4],
      [count - 1, count],
    ];
  }

  if (current === 4) {
    return [[1, 2, 3, 4, 5], [count]];
  }

  if (current === count - 2) {
    return [
      [1, 2],
      [count - 3, count - 2, count - 1, count],
    ];
  }

  if (current === count - 3) {
    return [[1], [count - 4, count - 3, count - 2, count - 1, count]];
  }

  return [[1], [current - 1, current, current + 1], [count]];
};
