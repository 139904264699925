import React from "react";
import { useTranslation } from "react-i18next";

import {
  CheckCircleIcon,
  EllipsisHorizontalCircleIcon,
  ExclamationCircleIcon,
  MinusCircleIcon,
  StopCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";

import { TaskStatus, TaskSubStatus } from "../apps/PrognosAI/models/task";
import CircularLoading from "./CircularLoading";

interface StatusIconProps {
  status?: TaskStatus | null;
  subStatus?: TaskSubStatus | null;
  className?: string;
}

export default function StatusIcon(props: StatusIconProps): JSX.Element | null {
  const { status, subStatus, className = "w-6 h-6" } = props;
  const { t } = useTranslation();

  switch (status) {
    case "Finished":
      if (subStatus === "WithWarnings") {
        return (
          <ExclamationCircleIcon
            className={`${className} text-yellow-500`}
            title={t("Finished with warnings")}
          />
        );
      }
      if (subStatus === "WithErrors") {
        return (
          <ExclamationCircleIcon
            className={`${className} text-red-500`}
            title={t("Finished with errors")}
          />
        );
      }
      return (
        <CheckCircleIcon
          className={`${className} text-lime-500`}
          title={t("Finished successfully")}
        />
      );
    case "Failed":
      return (
        <XCircleIcon
          className={`${className} text-red-500`}
          title={t("Failed")}
        />
      );
    case "Canceled":
      if (subStatus === "AbortedBySystem") {
        return (
          <StopCircleIcon
            className={`${className} text-red-500`}
            title={t("Canceled by system")}
          />
        );
      }
      return (
        <StopCircleIcon
          className={`${className} text-gray-400`}
          title={t("Canceled")}
        />
      );
    case "Started":
      return (
        <CircularLoading
          size={20}
          className="text-blue-400"
          title={t("Running")}
        />
      );
    case "Enqueued":
      return (
        <EllipsisHorizontalCircleIcon
          className={`${className} text-blue-400 animate-pulse`}
          title={t("Waiting")}
        />
      );
    case "Blocked":
      return (
        <EllipsisHorizontalCircleIcon
          className={`${className} text-yellow-500 animate-pulse`}
          title={t("Blocked")}
        />
      );
    case "Canceling":
      return (
        <MinusCircleIcon
          className={`${className} text-gray-400 animate-pulse`}
          title={t("Canceling")}
        />
      );
    case undefined:
    case null:
      return null;
  }
}
