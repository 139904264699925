import React from "react";

import Card from "../Card";
import SkeletonChart from "./SkeletonChart";
import SkeletonHero from "./SkeletonHero";
import SkeletonWrapper from "./SkeletonWrapper";

export default function BasicSkeleton(): JSX.Element {
  return (
    <SkeletonWrapper>
      <SkeletonHero />
      <Card
        className="py-6 border-2 border-gray-200 bg-transparent shadow-none space-y-4 flex items-center justify-center"
        style={{
          height: "calc(100vh - 148px)",
        }}
      >
        <div className="w-[40%]">
          <SkeletonChart />
        </div>
      </Card>
    </SkeletonWrapper>
  );
}
