import React from "react";

import classNames from "classnames";

import Card from "../Card";

interface SkeletonCardProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

export default function SkeletonCard(props: SkeletonCardProps): JSX.Element {
  const { children, style = {}, className = "" } = props;
  return (
    <Card
      className={classNames(
        "py-6 border-2 border-gray-200 bg-transparent shadow-none space-y-4 flex items-center justify-center",
        className
      )}
      style={{
        height: "calc(100vh - 244px)",
        ...style,
      }}
    >
      {children}
    </Card>
  );
}
