import React from "react";
import { useTranslation } from "react-i18next";

import env from "../services/env";
import PROGNOS_VERSION from "../services/version";
import { useAccount } from "./AuthProvider";

// inspiration: https://github.com/usersnap/public/blob/master/react-js/src/UsersnapContext.js

interface UsersnapProviderProps {
  children: React.ReactNode;
}

interface UsersnapParameters {
  locale: string;
}

interface UsersnapAPI {
  init: (options: UsersnapParameters) => Promise<void>;
  destroy: () => Promise<void>;
  logEvent: (eventName: string) => Promise<void>;
  on: (eventName: string, callback: (event: any) => void) => void;
  off: (eventName: string, callback: (event: any) => void) => void;
}

declare global {
  interface Window {
    onUsersnapLoad(api: UsersnapAPI): void;
  }
}

export type CustomValues = Record<
  string,
  string | number | boolean | null | undefined
>;

export type UsersnapContextArray = [
  UsersnapAPI | null,
  React.MutableRefObject<CustomValues>,
];

export const UsersnapContext = React.createContext<UsersnapContextArray | null>(
  null
);

export function useUsersnapAPI() {
  return React.useContext(UsersnapContext);
}

export default function UsersnapProvider(
  props: UsersnapProviderProps
): JSX.Element {
  const { children } = props;

  const { user } = useAccount() ?? { user: null };
  const { i18n } = useTranslation();
  const locale = i18n.languages[0] ?? "en";

  const [usersnapAPI, setUsersnapAPI] = React.useState<UsersnapAPI | null>(
    null
  );
  const customValuesRef = React.useRef<CustomValues>({});

  React.useEffect(() => {
    let currentAPI: UsersnapAPI;
    window.onUsersnapLoad = function (api) {
      api.init({ locale });

      api.on("open", function (event) {
        event.api.setValue("custom", {
          user,
          locale,
          version: PROGNOS_VERSION,
          ...customValuesRef.current,
        });
        if (user?.email) {
          event.api.setValue("visitor", user.email);
        }
      });

      currentAPI = api;
      setUsersnapAPI(api);
    };
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://widget.usersnap.com/load/${env.USERSNAP_KEY}?onload=onUsersnapLoad`;
    document.getElementsByTagName("head")[0].appendChild(script);

    return () => {
      if (currentAPI) {
        currentAPI.destroy();
      }
      script.remove();
    };
  }, [user, locale]);

  return (
    <UsersnapContext.Provider value={[usersnapAPI, customValuesRef]}>
      {children}
    </UsersnapContext.Provider>
  );
}
