import { EndpointOptions } from "../../../../api";
import { FilterSetting, SortSetting } from "../../../../models/primitives";
import useEndpointFilter from "./useEndpointFilter";
import useEndpointPage from "./useEndpointPage";
import useEndpointSort from "./useEndpointSort";

export type EndpointKey = string;

export default function useEndpointOptions(
  endpointKey: EndpointKey,
  defaults: EndpointOptions = {}
): {
  options: EndpointOptions;
  onSortChange(sort: SortSetting[]): void;
  onFilterChange(filter: FilterSetting[]): void;
  onPageChange(page: number): void;
} {
  const [sort, onSortChange] = useEndpointSort(endpointKey);
  const [filter, onFilterChange] = useEndpointFilter(endpointKey);
  const [page, onPageChange, pageSize] = useEndpointPage();

  const options: EndpointOptions = {
    ...defaults,
    sort: sort ?? defaults.sort,
    filter: filter ?? defaults.filter,
    page: page ?? defaults.page,
    pageSize: pageSize ?? defaults.pageSize,
  };

  return { options, onSortChange, onFilterChange, onPageChange };
}
