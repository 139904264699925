import { dateToDateString } from "../models/primitives";
import env from "./env";

export const FIX_VERSION = env.FIX_VERSION;
export const GIT_REVISION = env.GIT_REVISION ?? "n/A";
export const GIT_COMMIT = env.GIT_COMMIT ?? "n/A";
export const GIT_BRANCH = env.GIT_BRANCH ?? "n/A";
export const BUILD_DATE = dateToDateString(env.BUILDDATE);

const PROGNOS_VERSION = `${FIX_VERSION} ${BUILD_DATE} (${GIT_BRANCH}-${GIT_REVISION}-${GIT_COMMIT})`;

export default PROGNOS_VERSION;
