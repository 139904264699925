import { ZodType } from "zod";

import useStorage, { StorageSetCallback } from "./useStorage";

export default function useSessionStorage<
  T extends object | string | number | null | undefined,
>(
  key: string,
  defaultValue: T,
  parser?: ZodType<T>
): [T, StorageSetCallback<T>] {
  return useStorage(sessionStorage, key, defaultValue, parser);
}
