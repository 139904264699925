import {
  AlgorithmConfig,
  AlgorithmName,
  ArimaParameters,
  ArimaParametersZod,
  ExponentialSmoothingParameters,
  ExponentialSmoothingParametersZod,
  NaiveParameters,
  NaiveParametersZod,
  NbeatsParameters,
  NbeatsParametersZod,
  ProphetParameters,
  ProphetParametersZod,
  SeasonalRegressionParameters,
  SeasonalRegressionParametersZod,
} from "../../models/algorithmConfig";
import { Model, ModelDetail } from "../../models/model";

export const DEFAULT_ALGORITHM: Omit<
  AlgorithmConfig,
  | "algorithmConfigId"
  | "algorithmName"
  | "pipelinePosition"
  | "algorithmParameters"
> = {
  algorithmLabel: "",
  algorithmDescription: "",
  splitWeekdays: false,
  applyPartialIfsWhen: "Proportional",
  excludeAllInfluencingFactors: false,
  excludeSolutionInfluencingFactors: false,
  excludePartitionInfluencingFactors: false,
  useBusinessHourInfluencingFactor: false,
  covariateMeasurements: [],
};

export const getNewAlgorithmConfigSettings = (
  name: AlgorithmName,
  position: number
): AlgorithmConfig => {
  return {
    ...DEFAULT_ALGORITHM,
    algorithmConfigId: position,
    pipelinePosition: position,
    ...getAlgorithmDefault(name),
  };
};

export const getAlgorithmDefault = (
  algorithmName: AlgorithmName
):
  | { algorithmName: "Prophet"; algorithmParameters: ProphetParameters }
  | {
      algorithmName: "SeasonalRegression";
      algorithmParameters: SeasonalRegressionParameters;
    }
  | {
      algorithmName: "ExponentialSmoothing";
      algorithmParameters: ExponentialSmoothingParameters;
    }
  | { algorithmName: "Arima"; algorithmParameters: ArimaParameters }
  | { algorithmName: "Nbeats"; algorithmParameters: NbeatsParameters }
  | { algorithmName: "Naive"; algorithmParameters: NaiveParameters } => {
  switch (algorithmName) {
    case "Prophet":
      return {
        algorithmName,
        algorithmParameters: ProphetParametersZod.parse({}),
      };
    case "SeasonalRegression":
      return {
        algorithmName,
        algorithmParameters: SeasonalRegressionParametersZod.parse({}),
      };
    case "Naive":
      return {
        algorithmName,
        algorithmParameters: NaiveParametersZod.parse({}),
      };
    case "ExponentialSmoothing":
      return {
        algorithmName,
        algorithmParameters: ExponentialSmoothingParametersZod.parse({}),
      };
    case "Arima": {
      return {
        algorithmName,
        algorithmParameters: ArimaParametersZod.parse({}),
      };
    }
    case "Nbeats": {
      return {
        algorithmName,
        algorithmParameters: NbeatsParametersZod.parse({}),
      };
    }
  }
};

export const DEFAULT_MODEL: ModelDetail = {
  modelId: -1,
  name: "",
  version: "",
  algorithmConfigs: [getNewAlgorithmConfigSettings("Prophet", 1)],
  description: "",
};

export const getModelName = (
  model: Pick<Model, "name" | "version">
): string => {
  return (
    (model.name ? model.name : "New model") +
    (model.version ? ` (${model.version})` : "")
  );
};
