import { z } from "zod";

export const CsrfWrapperZod = z.object({
  csrftoken: z.string(),
});
export type CsrfWrapper = z.infer<typeof CsrfWrapperZod>;

export const UserZod = z.preprocess(
  (arg) =>
    typeof arg === "object" && arg !== null && "username" in arg
      ? { ...arg, userName: arg.username }
      : arg,
  z.object({
    userName: z.string().nullable(),
    firstName: z.string().nullable(),
    lastName: z.string().nullable(),
    email: z.string().nullable(),
  })
);
export type User = z.infer<typeof UserZod>;

export const AccountZod = z.object({
  isAuthenticated: z.boolean(),
  user: UserZod.nullable(),
});
export type Account = z.infer<typeof AccountZod>;
