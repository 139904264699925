import React from "react";

interface CircularLoadingProps {
  size?: number;
  className?: string;
  title?: string;
}

export default function CircularLoading({
  size,
  className = "",
  title,
}: CircularLoadingProps): JSX.Element {
  return (
    <div
      style={{ width: size, height: size, verticalAlign: "middle" }}
      className={`circular-loading inline-block ${className}`}
      title={title}
    ></div>
  );
}
