import { z } from "zod";

import { TaskZod } from "./task";

const messageTypes = ["TaskUpdate", "AggregatesAreDirty"] as const;
const MessageTypeZod = z.enum(messageTypes);
export type MessageType = z.infer<typeof MessageTypeZod>;

export const MessageZod = z.object({
  eventName: MessageTypeZod,
  eventArgs: z.any(),
});

export type Message = z.infer<typeof MessageZod>;

// dead code
export function getMessageTypeZod(type: MessageType) {
  switch (type) {
    case "TaskUpdate":
      return MessageZod.extend({ eventArgs: TaskZod });
  }
}
