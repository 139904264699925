import { TimeLocaleDefinition } from "d3-time-format";

import { TimeZoneFormatterFactory } from "../classes/TimeZoneFormatterFactory";
import { Aggregate, compareScales } from "../models/aggregate";
import { DayOfWeek, DayOfWeekZod } from "../models/primitives";

/**
 * Returns a timezone to display.
 *
 * The larger aggregates (from 1D) are always displayed in UTC.
 *
 * @param timezone default time zone
 * @param aggregate aggregate enum string
 * @returns time zone to display
 */
export function getDisplayTimeZone(
  timezone: string,
  aggregate: Aggregate
): string {
  if (compareScales(aggregate, ">=", "1D")) {
    return "UTC";
  }
  return timezone;
}

/**
 * Naively set the date's timezone.
 *
 * Probably does not behave correctly around corner cases (e.g. hours at DST change).
 *
 * !!! Very inefficient - don't use in loops (use the trick with tzDateISO instead).
 *
 * @param date date to be changed
 * @param timeZone time zone (e.g. "Europe/Vienna" or "UTC")
 * @returns date object with the changed time zone
 */
export function changeTimeZone(date: Date, timeZone: string): Date {
  return new Date(
    date.toLocaleString("en-US", {
      timeZone,
    })
  );
}

/**
 * Format the date into a ISO 8601 string in the given time zone.
 *
 * @param datetime date object
 * @param timeZone time zone (e.g. "Europe/Vienna" or "UTC")
 * @returns
 */
export function tzDateISO(datetime: Date, timeZone: string): string {
  const formatter = TimeZoneFormatterFactory.instance("iso", timeZone);
  return formatter.format(datetime);
}

/**
 * Get the datetime's day of week in the given time zone.
 *
 * @param datetime
 * @param timeZone
 * @returns day of week string in English
 */
export function tzDayOfWeek(
  datetime: Date | string | number,
  timeZone: string
): DayOfWeek {
  const localDate = datetime instanceof Date ? datetime : new Date(datetime);
  const formatter = TimeZoneFormatterFactory.instance("en", timeZone);
  const formattedDate = formatter.format(localDate);

  const [dayOfWeek] = formattedDate.split(", ");

  return DayOfWeekZod.parse(dayOfWeek);
}

/**
 * Format the datetime in the specified format and timezone.
 *
 * Uses the same formatting syntax as d3-time-format (https://github.com/d3/d3-time-format).
 *
 * @param datetime the date to format
 * @param formatString the formatting string using the d3-time-format syntax
 * @param timeZone time zone string (e.g. "Europe/Vienna")
 * @returns formatted date(time) string
 */
export function tzDateFormat(
  datetime: Date | string | number,
  formatString: string,
  timeZone: string,
  locale?: TimeLocaleDefinition
): string {
  const localDate = datetime instanceof Date ? datetime : new Date(datetime);
  const formatter = TimeZoneFormatterFactory.instance("en", timeZone);
  const formattedDate = formatter.format(localDate);

  const [dayOfWeek, date, time] = formattedDate.split(", ");
  const [month, day, year] = date.split("/");
  const [hour, minute, second] = time.split(":");

  const dayOfWeekIndex = getDayOfWeekIndex(dayOfWeek);
  const monthIndex = parseInt(month) - 1;

  const { hour12, amPm } = getHour12(hour);

  const naiveDate = changeTimeZone(localDate, timeZone);
  const [weekYear, week] = getWeekNumber(naiveDate);

  // TODO support all settings from https://github.com/d3/d3-time-format
  const formattedDateString = formatString
    // special meanings
    .replace("%%", "<percentage>")
    .replace("%c", locale?.dateTime ?? "%x, %X")
    // standard formats
    .replace("%a", locale?.shortDays[dayOfWeekIndex] ?? dayOfWeek)
    .replace("%A", locale?.days[dayOfWeekIndex] ?? dayOfWeek)
    .replace("%b", locale?.shortMonths[monthIndex] ?? month)
    .replace("%B", locale?.months[monthIndex] ?? month)
    .replace("%d", day)
    .replace("%e", day.replace(/^0/, " "))
    .replace("%f", `${localDate.getMilliseconds() * 1000}`)
    .replace("%g", "%g") // TODO
    .replace("%G", "%G") // TODO
    .replace("%H", hour)
    .replace("%I", hour12)
    .replace("%j", "%j") // TODO
    .replace("%L", `${localDate.getMilliseconds()}`)
    .replace("%m", month)
    .replace("%M", minute)
    .replace("%p", amPm)
    .replace("%q", `${getQuarterNumber(month)}`)
    .replace("%Q", `${localDate.valueOf()}`)
    .replace("%s", `${Math.floor(localDate.valueOf() / 1000)}`)
    .replace("%S", second)
    .replace("%u", `${(dayOfWeekIndex + 7) % 8}`)
    .replace("%U", "%U") // TODO
    .replace("%V", `${week < 10 ? "0" : ""}${week}`)
    .replace("%w", `${dayOfWeekIndex}`)
    .replace("%W", `${weekYear}`) // this is different from the d3-time-format
    .replace("%x", naiveDate.toLocaleDateString())
    .replace("%X", naiveDate.toLocaleTimeString())
    .replace("%y", year.slice(year.length - 2))
    .replace("%Y", year)
    // special meanings again
    .replace("<percentage>", "%");
  return formattedDateString;
}

const getQuarterNumber = (month: string): number => {
  return Math.floor((parseInt(month) - 1) / 3) + 1;
};

// https://stackoverflow.com/a/6117889/20181968
export const getWeekNumber = (d: Date): [number, number] => {
  // Copy date so don't modify original
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  // Get first day of year
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  // Calculate full weeks to nearest Thursday
  const weekNo = Math.ceil(
    ((d.valueOf() - yearStart.valueOf()) / 86400000 + 1) / 7
  );
  // Return array of year and week number
  return [d.getUTCFullYear(), weekNo];
};

const getHour12 = (hour: string): { hour12: string; amPm: string } => {
  const hourNum = parseInt(hour);

  if (hourNum === 0) {
    // midnight
    return { hour12: "12", amPm: "AM" };
  }

  if (hourNum < 12) {
    // morning
    return { hour12: hour, amPm: "AM" };
  }

  if (hourNum === 12) {
    // noon
    return { hour12: "12", amPm: "PM" };
  }

  // afternoon
  return { hour12: (hourNum - 12).toString(), amPm: "PM" };
};

const getDayOfWeekIndex = (day: string): number => {
  switch (day) {
    case "Sunday":
      return 0;
    case "Monday":
      return 1;
    case "Tuesday":
      return 2;
    case "Wednesday":
      return 3;
    case "Thursday":
      return 4;
    case "Friday":
      return 5;
    case "Saturday":
      return 6;
    default:
      throw new Error(`The day "${day}" is not supported!`);
  }
};
