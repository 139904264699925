// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hidden-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.hidden-scrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
`, "",{"version":3,"sources":["webpack://./src/styles/components/HiddenScrollbar.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB,EAAE,0BAA0B;EACpD,qBAAqB,EAAE,YAAY;AACrC;AACA;EACE,aAAa,EAAE,sBAAsB;AACvC","sourcesContent":[".hidden-scrollbar {\n  -ms-overflow-style: none; /* Internet Explorer 10+ */\n  scrollbar-width: none; /* Firefox */\n}\n.hidden-scrollbar::-webkit-scrollbar {\n  display: none; /* Safari and Chrome */\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
