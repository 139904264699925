import React from "react";
import { useTranslation } from "react-i18next";

import { User } from "../../../../models/auth";
import DateTime from "./DateTime";

interface AuditProps {
  date: Date;
  user: User | null;
}

export default function Audit(props: AuditProps): JSX.Element {
  const { date, user } = props;

  const userName = getUserName(user);

  const { t } = useTranslation();

  return (
    <>
      <DateTime value={date} aggregate="1h" />
      {!!userName && (
        <span>
          {" "}
          {t("by")} {userName}
        </span>
      )}
    </>
  );
}

export function getUserName(user: User | null): string | null {
  if (user === null) {
    return null;
  }

  if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`;
  }

  if (user.userName) {
    return user.userName;
  }

  if (user.email) {
    return user.email;
  }

  return null;
}
