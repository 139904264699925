import {
  FilterSetting,
  FilterSettingZod,
  filterOperators,
} from "../models/primitives";

export default class Filter {
  public static parse(str: string): FilterSetting[] {
    // TODO parse using "ebnf"
    return str
      .split(" , ")
      .map((v) => {
        const expr = v.match(filterValueRegExp);
        if (!expr) {
          return undefined;
        }
        const param = expr[1];
        const operator = expr[2];
        const value = unescapeValue(expr[3] ?? "");
        const result = FilterSettingZod.safeParse([param, operator, value]);
        return result.success ? result.data : undefined;
      })
      .filter(isFilterValue);
  }

  public static stringify(
    filter: FilterSetting[],
    mode: "AND" | "OR" = "AND"
  ): string {
    return filter
      .map(([key, operator, values]) => {
        const strings = (Array.isArray(values) ? values : [values]).map(
          (value) => `${key} ${operator} ${escapeValue(value)}`
        );
        return strings.length > 1
          ? `(${strings.join(" | ")})`
          : (strings.at(0) ?? "");
      })
      .filter((value) => value !== "")
      .join(mode === "AND" ? " , " : " | ");
  }
}

const isFilterValue = (
  item: FilterSetting | undefined
): item is FilterSetting => {
  return typeof item !== "undefined";
};

const filterValueRegExp = new RegExp(
  `^([\\w.]*) (${filterOperators
    .map((o) => o.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"))
    .join("|")}) ('?.*)?$`
);

const escapeValue = <T>(value: T): T | string => {
  if (typeof value !== "string") {
    return value;
  }

  // escape backslashes, then escape single quotes, then wrap everything into single quotes
  return `'${value.replaceAll("\\", "\\\\").replaceAll("'", "\\'")}'`;
};

const unescapeValue = <T>(value: T): T | string => {
  if (typeof value !== "string") {
    return value;
  }

  // unwrap from single quotes, unescape single quotes, then unescape backslashes
  return value
    .replace(/^'/, "")
    .replace(/'$/, "")
    .replaceAll("\\'", "'")
    .replaceAll("\\\\", "\\");
};
