import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import { solutionQuery } from "../api/solutions";
import { getAppByPrefix, getAppName, getAppPathPrefix } from "../routes";
import { getSolutionPath } from "../routes/solutions";
import Breadcrumb from "./Breadcrumb";
import Breadcrumbs from "./Breadcrumbs";

interface PathLevel {
  label: React.ReactNode;
  address: string;
}

export type HeroPath = (PathLevel | null)[];

interface HeroBreadcrumbsProps {
  path: HeroPath;
  hideRootPath?: boolean;
}

export default function HeroBreadcrumbs(
  props: HeroBreadcrumbsProps
): JSX.Element {
  const { path, hideRootPath } = props;

  const { solutionId } = useParams();
  const { data: solution } = useQuery(solutionQuery(solutionId));

  const { t } = useTranslation();

  const fullPath: HeroPath = [...path];
  if (!hideRootPath) {
    if (solutionId) {
      const app = getAppByPrefix(solutionId, window.location.pathname);
      if (app) {
        fullPath.unshift({
          address: getAppPathPrefix(solutionId, app),
          label: getAppName(app),
        });
      }

      fullPath.unshift(
        solution
          ? { address: getSolutionPath(solutionId), label: solution.name }
          : null
      );
    }
    fullPath.unshift({ address: "/auth", label: t("Projects") });
  }

  return (
    <Breadcrumbs>
      {fullPath.map((level, index) =>
        level ? (
          <Breadcrumb key={level.address} to={level.address}>
            {truncateLabel(level.label)}
          </Breadcrumb>
        ) : (
          <div
            key={index}
            className="w-20 h-4 rounded-full animate-pulse bg-gray-200"
          ></div>
        )
      )}
    </Breadcrumbs>
  );
}

const LABEL_MAX_LEN = 40;
function truncateLabel(label: React.ReactNode): React.ReactNode {
  if (typeof label !== "string") {
    return label;
  }

  if (label.length > LABEL_MAX_LEN) {
    return <span title={label}>{label.slice(0, LABEL_MAX_LEN).trim()}...</span>;
  }

  return label;
}
