import React from "react";
import { useTranslation } from "react-i18next";

import { EyeSlashIcon, FunnelIcon, XMarkIcon } from "@heroicons/react/20/solid";

interface DataTableFilterControlProps {
  id: string;
  state: "hidden" | "shown" | "set";
  className?: string;
  onShow(): void;
  onHide(): void;
  onReset(): void;
}

export default function DataTableFilterControl(
  props: DataTableFilterControlProps
): JSX.Element {
  const { id, state, className = "", onShow, onHide, onReset } = props;

  const { t } = useTranslation();

  return (
    <div id={id} className={`text-xs underline text-blue-500 ${className}`}>
      {state === "hidden" && (
        <button
          id={`${id}-showFilters`}
          onClick={onShow}
          className="flex items-center space-x-1"
        >
          <FunnelIcon className="w-2 h-2" />
          <span>{t("Show filters")}</span>
        </button>
      )}
      {state === "set" && (
        <button
          id={`${id}-resetFilters`}
          onClick={onReset}
          className="flex items-center space-x-1"
        >
          <XMarkIcon className="w-3 h-3" />
          <span>{t("Reset filters")}</span>
        </button>
      )}
      {state === "shown" && (
        <button
          id={`${id}-hideFilters`}
          onClick={onHide}
          className="flex items-center space-x-1"
        >
          <EyeSlashIcon className="w-2 h-2" />
          <span>{t("Hide filters")}</span>
        </button>
      )}
    </div>
  );
}
