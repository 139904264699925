import React from "react";

import { ZodType } from "zod";

export type StorageSetCallback<T> = (change: T | ((prev: T) => T)) => void;

export default function useStorage<
  T extends object | string | number | null | undefined,
>(
  storage: Storage,
  key: string,
  defaultValue: T,
  parser?: ZodType<T>
): [T, StorageSetCallback<T>] {
  const [value, setValue] = React.useState<T>(defaultValue);
  React.useEffect(() => {
    const rawValue = storage.getItem(key);
    if (typeof rawValue === "string") {
      try {
        const value = JSON.parse(rawValue).value;
        setValue(parser ? parser.parse(value) : value);
      } catch {
        storage.removeItem(key);
      }
    }
  }, []);

  const handleValueChange: StorageSetCallback<T> = (change) => {
    const newValue = typeof change === "function" ? change(value) : change;
    storage.setItem(key, JSON.stringify({ value: newValue }));
    setValue(newValue);
  };

  return [value, handleValueChange];
}
