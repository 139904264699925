import React from "react";

import { FILTER_EL_STYLE } from ".";

export interface InputProps {
  id: string;
  refs: React.RefObject<HTMLInputElement>;
  style?: React.StyleHTMLAttributes<HTMLInputElement>;
  value: string;
  type?: React.HTMLInputTypeAttribute;
  className?: string;
  disabled?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
}

export default function Input(props: InputProps) {
  const {
    value,
    refs,
    onChange,
    onKeyDown,
    style = {},
    type = "number",
    className = "",
    ...rest
  } = props;

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    inputStopPropagation(e);
    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  return (
    <div className="grow ml-0.5">
      <input
        {...rest}
        className={className + " w-full pl-2 " + FILTER_EL_STYLE}
        style={{ ...style, lineHeight: "28px" }}
        type={type}
        ref={refs}
        value={value}
        onChange={onChange}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
}

function inputStopPropagation(event: React.KeyboardEvent<HTMLInputElement>) {
  if (["ArrowLeft", "ArrowRight"].includes(event.key)) {
    event.stopPropagation();
  }
}
