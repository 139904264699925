import React from "react";
import { RouteObject } from "react-router";

import lazyWithRetry from "../../../services/lazyWithRetry";
import AnalysisMinScale from "../components/AnalysisMinScale";
import { getAnalysisPath } from "./analyzer";

const YearsComparison = lazyWithRetry(
  () => import("../pages/YearsComparison/YearsComparison")
);

const YEARS_COMPARISON_PATH = "yearsComparison";

export const yearsComparisonRoute: RouteObject = {
  path: YEARS_COMPARISON_PATH,
  element: (
    <AnalysisMinScale scaleTooHigh="1Y">
      <YearsComparison />
    </AnalysisMinScale>
  ),
};

export function getYearsComparisonPath(
  solutionId: string | number,
  analysisId: string | number
): string {
  return `${getAnalysisPath(solutionId, analysisId)}/${YEARS_COMPARISON_PATH}`;
}
