import React from "react";
import { RouteObject } from "react-router";

import lazyWithRetry from "../../../services/lazyWithRetry";
import { getAnalysisPath } from "./analyzer";

const Overview = lazyWithRetry(() => import("../pages/Overview/Overview"));

const OVERVIEW_PATH = "overview";

export const overviewRoute: RouteObject = {
  path: OVERVIEW_PATH,
  element: <Overview />,
};

export function getOverviewPath(
  solutionId: string | number,
  analysisId: string | number
): string {
  return `${getAnalysisPath(solutionId, analysisId)}/${OVERVIEW_PATH}`;
}
