import React from "react";
import toast, {
  Toast,
  ToastBar,
  Toaster,
  useToasterStore,
} from "react-hot-toast";

import { XMarkIcon } from "@heroicons/react/20/solid";

declare global {
  interface Window {
    currentNotifications?: Toast[];
  }
}

export default function CustomToaster(): JSX.Element {
  const { toasts } = useToasterStore();

  const TOAST_LIMIT = 3;

  React.useEffect(() => {
    toasts
      .filter((t) => t.visible)
      .filter((_, i) => i >= TOAST_LIMIT)
      .forEach((t) => toast.dismiss(t.id));

    // variable for browser testing
    window.currentNotifications = toasts.filter((t) => t.visible);
  }, [toasts]);

  return (
    <Toaster
      position="top-right"
      reverseOrder
      toastOptions={{
        className: "w-96 mx-4",
        error: { duration: 10 * 1000 },
      }}
    >
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <div className="w-full flex justify-between items-center">
              <div className="flex items-center">
                {icon}
                <span style={{ textAlign: "left" }}>{message}</span>
              </div>
              {t.type !== "loading" && (
                <button
                  id={`closeToast-${t.id}`}
                  onClick={() => toast.dismiss(t.id)}
                >
                  <XMarkIcon className="w-4 h-4 text-gray-400" />
                </button>
              )}
            </div>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
}
