const BUTTON_COMMON_STYLE =
  "inline-flex items-center justify-center rounded font-medium focus:outline-none " +
  "disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-black disabled:border-gray-300 transition-colors duration-300";

const BUTTON_PRIMARY =
  "bg-blue-600 text-white hover:bg-blue-700 border border-transparent focus:ring-blue-500";
const BUTTON_SECONDARY =
  "bg-blue-100 text-blue-700 hover:bg-blue-200 border border-transparent focus:ring-blue-500";
const BUTTON_WHITE =
  "bg-white text-black hover:bg-slate-200 border border-gray-300 focus:ring-blue-300";
const BUTTON_RED =
  "bg-white text-red-500 font-bold hover:bg-red-100 hover:text-red-600 border border-red-500 focus:ring-red-300";

const BUTTON_SMALL = "px-2.5 py-1.5 text-xs focus:ring-1 focus:ring-offset-1";
const BUTTON_MEDIUM = "px-4 py-2 text-sm focus:ring-2 focus:ring-offset-2";

export const BUTTON_ICON_SMALL = "h-3 w-3";
export const BUTTON_ICON_MEDIUM = "h-5 w-5";

export type ButtonVariant = "primary" | "secondary" | "white" | "red";
export type ButtonSize = "small" | "medium";

export const getButtonStyle = (
  variant: ButtonVariant,
  size: ButtonSize,
  className?: string
): string => {
  let base = BUTTON_COMMON_STYLE;
  switch (variant) {
    case "primary":
      base += " " + BUTTON_PRIMARY;
      break;
    case "secondary":
      base += " " + BUTTON_SECONDARY;
      break;
    case "white":
      base += " " + BUTTON_WHITE;
      break;
    case "red":
      base += " " + BUTTON_RED;
      break;
  }
  switch (size) {
    case "small":
      base += " " + BUTTON_SMALL;
      break;
    case "medium":
      base += " " + BUTTON_MEDIUM;
      break;
  }
  if (className) {
    base += " " + className;
  }

  return base;
};
