import { z } from "zod";

import { DateTimeZod } from "../models/primitives";

const EnvZod = z.object({
  NODE_ENV: z.enum(["development", "production"]).catch("production"),
  API_URL: z.string().optional(),
  USERSNAP_KEY: z.string().optional(),
  WS_URL: z.string().optional(),
  FIX_VERSION: z.string().catch("n/A"),
  GIT_REVISION: z.string().catch("n/A"),
  GIT_COMMIT: z.string().catch("n/A"),
  GIT_BRANCH: z.string().catch("n/A"),
  BUILDDATE: DateTimeZod.catch(new Date()),
  GPU_AVAILABLE: z
    .string()
    .catch("false")
    .transform((arg) => arg.trim().toLowerCase() === "true"),
});

type Env = z.infer<typeof EnvZod>;

// this is unfortunately necessary:
// https://github.com/mrsteele/dotenv-webpack/issues/70
const env = EnvZod.parse({
  NODE_ENV: process.env.NODE_ENV,
  API_URL: process.env.API_URL,
  USERSNAP_KEY: process.env.USERSNAP_KEY,
  WS_URL: process.env.WS_URL,
  FIX_VERSION: process.env.FIX_VERSION,
  GIT_REVISION: process.env.GIT_REVISION,
  GIT_COMMIT: process.env.GIT_COMMIT,
  GIT_BRANCH: process.env.GIT_BRANCH,
  BUILDDATE: process.env.BUILDDATE,
  GPU_AVAILABLE: process.env.GPU_AVAILABLE,
} satisfies { [key in keyof Env]: unknown });

export default env;
