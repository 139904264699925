import React from "react";
import { PlacesType, VariantType } from "react-tooltip";

import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";

import IconTooltip from "./IconTooltip";

interface HelpTooltipProps {
  id: string;
  text: string;
  variant?: VariantType;
  place?: PlacesType;
  className?: string;
}

export default function HelpTooltip(props: HelpTooltipProps): JSX.Element {
  const { id, text, variant = "dark", place, className = "" } = props;
  return (
    <IconTooltip
      id={id}
      text={text}
      variant={variant}
      place={place}
      iconClassName="text-blue-400 hover:text-blue-500"
      tooltipClassName={className}
      icon={QuestionMarkCircleIcon}
    />
  );
}
