import React from "react";
import { Link } from "react-router-dom";

interface BreadcrumbProps {
  to: string;
  children: React.ReactNode;
}

export default function Breadcrumb(props: BreadcrumbProps): JSX.Element {
  const { children, to } = props;
  return (
    <Link
      to={to}
      className="no-underline hover:underline hover:underline-offset-2 text-slate-400"
    >
      {children}
    </Link>
  );
}
