import axios from "axios";

import Filter from "./classes/Filter";
import {
  FilterSetting,
  SortSetting,
  SortSettingZod,
} from "./models/primitives";
import env from "./services/env";

const api = axios.create({
  baseURL: env.API_URL,
  withCredentials: true,
  // Django CSRF token settings:
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFTOKEN",
});

export default api;

export interface EndpointOptions {
  sort?: SortSetting[];
  filter?: FilterSetting[];
  filterMode?: "AND" | "OR";
  page?: number;
  pageSize?: number;
}

export const stringifyOrderBy = (items: SortSetting[]): string =>
  items.map((v) => `${v[0]} ${v[1]}`).join(", ");

export const getQueryString = (options: EndpointOptions): string => {
  const params = new URLSearchParams();
  if (options.sort?.length) {
    params.append("orderBy", `"${stringifyOrderBy(options.sort)}"`);
  }
  if (options.filter?.length) {
    params.append(
      "filtering",
      `"${Filter.stringify(options.filter, options.filterMode)}"`
    );
  }
  if (options.page) {
    params.append("page", options.page.toString());
  }
  if (options.pageSize) {
    params.append("pageSize", options.pageSize.toString());
  }
  return params.toString();
};

const isSortValue = (item: SortSetting | undefined): item is SortSetting => {
  return typeof item !== "undefined";
};

export const parseOrderBy = (value: string): SortSetting[] =>
  value
    .split(", ")
    .map((v) => {
      const result = SortSettingZod.safeParse(v.split(" "));
      return result.success ? result.data : undefined;
    })
    .filter(isSortValue);

export const parsePage = (value: string): number | undefined => {
  const parsed = parseInt(value);
  if (isNaN(parsed)) {
    return undefined;
  }

  return parsed;
};

export const parsePageSize = (value: string): number | undefined =>
  parsePage(value);
