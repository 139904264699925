import React from "react";

import useDebounce from "../../hooks/useDebounce";
import Input, { InputProps } from "./Input";

interface DebouncedTextFieldProps extends InputProps {
  delay?: number;
}

const DebouncedInput = (props: DebouncedTextFieldProps): JSX.Element => {
  const { value, onChange, delay = 300, ...rest } = props;

  const [innerValue, setInnerValue] = React.useState(value);

  React.useEffect(() => {
    setInnerValue(value);
  }, [value]);

  const handleDebouncedChange = useDebounce(
    (e: React.ChangeEvent<HTMLInputElement>) => (onChange ? onChange(e) : ""),
    delay
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInnerValue(e.target.value);
    handleDebouncedChange(e);
  };

  return <Input {...rest} value={innerValue} onChange={handleChange} />;
};

export default DebouncedInput;
