import React from "react";

import {
  Measurement,
  getMeasurementNameUnit,
} from "../apps/PrognosAI/models/measurement";

interface SegmentNameProps {
  partitionName: string;
  measurement: Measurement | null | undefined;
}

export default function SegmentName(
  props: SegmentNameProps
): JSX.Element | null {
  const { partitionName, measurement } = props;

  if (!measurement) {
    return <>{partitionName}</>;
  }

  return (
    <>
      {partitionName}
      {
        <span className="font-normal text-gray-400">
          {" - "}
          {getMeasurementNameUnit(measurement)}
        </span>
      }
    </>
  );
}
