import { z } from "zod";

import { DateZod } from "../../../models/primitives";
import { DayOfWeek, DayOfWeekZod } from "../../../models/primitives";

const TimeStringZod = z.string().regex(/^\d\d:\d\d:\d\d$/);

export const TimeIntervalZod = z.object({
  timeIntervalId: z.number(),
  timeFrom: TimeStringZod.nullable().optional(),
  timeTo: TimeStringZod.nullable().optional(),
});
export type TimeInterval = z.infer<typeof TimeIntervalZod>;

export const BusinessDayZod = z.object({
  businessDayId: z.number(),
  dayOfWeek: DayOfWeekZod,
  isOpen: z.boolean(),
  timeIntervals: TimeIntervalZod.array(),
});
export type BusinessDay = z.infer<typeof BusinessDayZod>;

export const BusinessRuleSetZod = z.object({
  businessRuleSetId: z.number(),
  solutionId: z.number(),
  isSolutionDefault: z.boolean(),
  partitionId: z.number().nullable(),
  dateFrom: DateZod.nullable(),
  dateTo: DateZod.nullable(),
  /* derivate <=> !!dateFrom && !!dateTo */
  isDefault: z.boolean(),
  description: z.string(),
  businessDays: BusinessDayZod.array(),
});
export type BusinessRuleSet = z.infer<typeof BusinessRuleSetZod>;

export const DefaultBusinessHoursZod = z.object({
  businessRuleSet: BusinessRuleSetZod.nullable().optional(),
  businessExceptionSets: BusinessRuleSetZod.array(),
});
export type DefaultBusinessHours = z.infer<typeof DefaultBusinessHoursZod>;

export function getDefaultRuleSet(
  solutionId: number,
  isSolutionDefault: boolean,
  partitionId: number | null,
  dateFrom: string | null = null,
  dateTo: string | null = null
): Omit<BusinessRuleSet, "businessRuleSetId"> {
  return {
    solutionId,
    isSolutionDefault,
    partitionId,
    dateFrom,
    dateTo,
    isDefault: !dateFrom && !dateTo,
    description: "",
    businessDays: [],
  };
}

export function getDefaultBusinessDay(
  dayOfWeek: DayOfWeek
): Omit<BusinessDay, "businessDayId"> {
  return {
    dayOfWeek,
    isOpen: true,
    timeIntervals: [],
  };
}

export function getDefaultTimeInterval(
  sortedIntervals: TimeInterval[],
  afterPosition: number
): Omit<TimeInterval, "timeIntervalId"> {
  const prev = sortedIntervals.at(afterPosition);
  let timeFrom = "00:00:00";
  if (prev) {
    const lastHour = prev.timeTo ?? "";
    const hour = parseInt(lastHour.split(":")[0]);
    if (hour && hour <= 23) {
      timeFrom = `${hour.toString().padStart(2, "0")}:00:00`;
    }
  }

  const next = sortedIntervals.at(afterPosition + 1);
  let timeTo = "00:00:00";
  if (next) {
    const firstHour = next.timeFrom ?? "";
    const hour = parseInt(firstHour.split(":")[0]);
    if (hour && hour <= 23) {
      timeTo = `${hour.toString().padStart(2, "0")}:00:00`;
    }
  }

  return { timeFrom, timeTo };
}
