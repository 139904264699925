import { z } from "zod";

import { DateTimeZod } from "../../../models/primitives";
import { TaskStatusZod, TaskSubStatusZod } from "./task";

export const ModelRunConfigZod = z.object({
  modelRunConfigId: z.number(),
  runConfigId: z.number(),
  modelId: z.number(),
  solutionId: z.number(),
  lastExecuted: DateTimeZod.nullable().optional(),
  lastStatus: TaskStatusZod.nullable().optional().catch(undefined),
  lastSubStatus: TaskSubStatusZod.nullable().optional().catch(undefined),
  lastResultId: z.number().nullable().optional(),
});
export type ModelRunConfig = z.infer<typeof ModelRunConfigZod>;
