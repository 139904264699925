import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import classNames from "classnames";

import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

import Button from "../../../../components/buttons/Button";

interface AlertDialogProps {
  id: string;
  title: React.ReactNode;
  children: React.ReactNode;
  type: "info" | "warning" | "error";
  open: boolean;
  closeLabel?: string;
  onClose(): void;
}

export default function AlertDialog(props: AlertDialogProps) {
  const { id, title, children, type, open, onClose } = props;

  const { t } = useTranslation();

  const { closeLabel = t("OK") } = props;

  return (
    <Transition show={open} as={Fragment}>
      <Dialog id={id} as="div" className="relative z-10" onClose={onClose}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    id={`${id}-xCloseButton`}
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-200 focus:ring-offset-1"
                    onClick={onClose}
                  >
                    <span className="sr-only">{t("Close")}</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="w-full flex items-start">
                  <AlertIcon type={type} />
                  <div className="w-full mt-0 ml-4">
                    <div className="h-10 flex items-center">
                      <DialogTitle
                        as="h3"
                        className="align-middle text-lg font-medium leading-6 text-gray-900"
                      >
                        {title}
                      </DialogTitle>
                    </div>
                    <div className="w-full mt-2 text-base md:text-sm text-gray-500">
                      {children}
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 space-x-2 flex justify-end">
                  <Button
                    id={`${id}-mainCloseButton`}
                    variant="secondary"
                    onClick={onClose}
                  >
                    {closeLabel}
                  </Button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

interface AlertIconProps {
  type: "info" | "warning" | "error";
}

function AlertIcon(props: AlertIconProps): JSX.Element {
  const { type } = props;

  const divClassName =
    "mx-auto flex flex-shrink-0 items-center justify-center rounded-full sm:mx-0 h-10 w-10";
  const iconClassName = "h-6 w-6";

  switch (type) {
    case "info":
      return (
        <div className={classNames(divClassName, "bg-blue-100")}>
          <InformationCircleIcon
            className={classNames(iconClassName, "text-blue-600")}
          />
        </div>
      );
    case "warning":
      return (
        <div className={classNames(divClassName, "bg-orange-100")}>
          <ExclamationTriangleIcon
            className={classNames(iconClassName, "text-orange-600")}
          />
        </div>
      );
    case "error":
      return (
        <div className={classNames(divClassName, "bg-red-100")}>
          <ExclamationCircleIcon
            className={classNames(iconClassName, "text-red-600")}
          />
        </div>
      );
  }
}
