import { initReactI18next } from "react-i18next";

import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

import env from "./env";
import { BUILD_DATE } from "./version";

/**
 * List of supported languages. In order to add a new language:
 *  1. add a new entry into this list,
 *  2. create a new directory containing `translation.json`
 *     in the _public/locales/_ folder.
 */
export const supportedLanguages = [
  { code: "en", name: "English" },
  { code: "de", name: "Deutsch" },
] as const;

i18next
  .use(initReactI18next)
  .use(HttpApi)
  .use(LanguageDetector)
  .init({
    load: "languageOnly",
    supportedLngs: supportedLanguages.map((l) => l.code),
    nonExplicitSupportedLngs: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    debug: env.NODE_ENV === "development",
    // remove this to allow structured JSON
    keySeparator: false,
    backend: {
      // force browser to refetch jsons after each build
      // suboptimal, but should be good enough
      queryStringParams: { v: BUILD_DATE },
    },
  });

export default i18next;
