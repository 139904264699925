import { z } from "zod";

import Filter from "../../../../classes/Filter";
import { FilterSetting } from "../../../../models/primitives";
import useSessionStorage from "../useSessionStorage";
import { EndpointKey } from "./useEndpointOptions";

export default function useEndpointFilter(
  endpointKey: EndpointKey
): [FilterSetting[] | undefined, (filter: FilterSetting[]) => void] {
  const [filter, setFilter] = useSessionStorage(
    `EndpointFilter-${endpointKey}`,
    undefined,
    z.string().optional()
  );

  let value: FilterSetting[] | undefined = undefined;
  if (filter) {
    const parsedFilter = Filter.parse(filter);
    value = parsedFilter.length > 0 ? parsedFilter : undefined;
  }

  const onChange = (filter: FilterSetting[]) =>
    setFilter(Filter.stringify(filter));

  return [value, onChange];
}
