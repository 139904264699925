import { AlgorithmConfig } from "../models/algorithmConfig";

export default function useSortedAlgorithms<T extends AlgorithmConfig>(
  algorithms: T[]
): T[] {
  const sortedAlgos = [...algorithms];
  sortedAlgos.sort(compareAlgorithms);

  return sortedAlgos;
}

export const compareAlgorithms = (a: AlgorithmConfig, b: AlgorithmConfig) =>
  a.pipelinePosition - b.pipelinePosition;
