import React from "react";
import { useTranslation } from "react-i18next";

import { useQueryClient } from "@tanstack/react-query";

import { importExportConfigQuery } from "../api/importExport";
import { activeExportTasksQuery } from "../api/tasks";
import { Message } from "../models/message";
import {
  GeneralSolutionTaskZod,
  TaskName,
  TaskStatus,
  finalTaskStates,
} from "../models/task";

export default function useExportMessageCallback() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const callback = React.useCallback(
    (msg: Message) => {
      const parseInfo = GeneralSolutionTaskZod.safeParse(msg.eventArgs);
      if (!parseInfo.success) {
        return;
      }
      const task = parseInfo.data;
      const solutionId = task.solutionId.toString();

      if (!task.taskName.startsWith("Export")) {
        return;
      }

      const continuousKeysToInvalidate = (
        [
          "ExportResult",
          "ExportIntradayProfiles",
          "ExportBusinessHours",
          "ExportInfluencingFactors",
        ] satisfies TaskName[]
      ).flatMap((entity) => [
        activeExportTasksQuery(solutionId, entity),
        activeExportTasksQuery(
          solutionId,
          entity,
          task.importExportConfigId?.toString()
        ),
      ]);

      for (const query of continuousKeysToInvalidate) {
        queryClient.invalidateQueries(query);
      }

      const finalKeysToInvalidate = [
        //resultExportsQuery(solutionId, resultId).queryKey,
        { queryKey: ["importExportConfigs", solutionId, "resultExports"] },
      ];

      if (task.importExportConfigId) {
        finalKeysToInvalidate.push(
          importExportConfigQuery(task.importExportConfigId)
        );
      }

      if ((finalTaskStates as readonly TaskStatus[]).includes(task.status)) {
        for (const query of finalKeysToInvalidate) {
          queryClient.invalidateQueries(query);
        }
      }
    },
    [queryClient, t]
  );

  return callback;
}
