import { z } from "zod";

export const paginatedResponse = <T extends z.ZodTypeAny>(items: T) => {
  return z.object({
    items,
    count: z.number(),
  });
};

export type PaginatedResponse<T> = {
  items: T;
  count: number;
};
