import { useTranslation } from "react-i18next";

import { useQuery } from "@tanstack/react-query";
import { TFunction } from "i18next";

import {
  ArrowPathIcon,
  NoSymbolIcon,
  PlayCircleIcon,
  StopCircleIcon,
} from "@heroicons/react/24/outline";

import { HeroIcon } from "../../../../models/primitives";
import { activeAggregateCalcTasksQuery } from "../../api/tasks";
import { getTasksProgress } from "../../functions/task";
import { ModelRunTask, runningNonCanceledTasks } from "../../models/task";
import { tenum } from "../../services/translationMappings";

type TaskAction = "start" | "cancel" | "restart" | "none";

export function getTaskActionIcon(action: TaskAction): HeroIcon {
  switch (action) {
    case "start":
      return PlayCircleIcon;
    case "cancel":
      return StopCircleIcon;
    case "none":
      return NoSymbolIcon;
    case "restart":
      return ArrowPathIcon;
  }
}

export const getCancelButton = (
  row: { tasks: string },
  onCancel: (taskId: string) => void,
  t: TFunction<"translation", undefined>
) => {
  const tasks = JSON.parse(row.tasks) as ModelRunTask[];

  let icon = getTaskActionIcon("cancel");
  let title = t("Stop the run");

  if (tasks.some((t) => t.status === "Canceling")) {
    title = t("Stopping the run");
    icon = getTaskActionIcon("none");
  }

  return {
    title,
    onClick: () =>
      tasks
        .filter((t) => runningNonCanceledTasks.includes(t.status))
        .forEach((t) => onCancel(t.taskId)),
    icon,
  };
};

export function useModelRunTasksStatus(solutionId: string | number) {
  const { t } = useTranslation();

  const { data: { items: aggregateTasks = [] } = {} } = useQuery(
    activeAggregateCalcTasksQuery(solutionId)
  );

  return (tasks: ModelRunTask[]): string => {
    if (tasks.some((t) => t.status === "Canceling")) {
      return t("Canceling");
    }

    if (tasks.some((t) => t.status === "Started")) {
      const progress = getTasksProgress(tasks);
      return progress
        ? t("Running ({{progress}}% finished)", { progress })
        : t("Running");
    }

    if (tasks.some((t) => t.status === "Enqueued")) {
      if (aggregateTasks.length > 0) {
        const progress = getTasksProgress(aggregateTasks);
        return t("Refreshing aggregates ({{progress}}% finished)", {
          progress,
        });
      }
      return t("Waiting");
    }

    if (tasks.some((t) => t.status === "Blocked")) {
      return t("Blocked");
    }

    return [...new Set(tasks.map(({ status }) => tenum(status, t)))].join(", ");
  };
}
