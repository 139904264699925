import { useSearchParams } from "react-router-dom";

export default function useSearchBoolean(
  name: string
): [boolean | null, (change: boolean | null) => void] {
  const [searchParams, setSearchParams] = useSearchParams();

  const value = searchParams.has(name)
    ? searchParams.get(name) === "True"
    : null;

  const onChange = (newValue: boolean | null) => {
    if (newValue === null) {
      searchParams.delete(name);
    } else {
      searchParams.set(name, newValue ? "True" : "False");
    }
    setSearchParams(searchParams);
  };

  return [value, onChange];
}
