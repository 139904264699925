import { z } from "zod";

export const importExportFileTypes = ["xlsx", "txt", "csv", "tsv"] as const;
export const ImportExportFileTypeZod = z.enum(importExportFileTypes);
export type ImportExportFileType = z.infer<typeof ImportExportFileTypeZod>;

// prettier-multiline-arrays-next-line-pattern: 8
export const importExportSeparators = [
  ",",
  ", ",
  ";",
  "; ",
  "\t",
  "\t,",
  "\t;",
  ":",
] as const;
export const ImportExportSeparatorZod = z.enum(importExportSeparators);
export type ImportExportSeparator = z.infer<typeof ImportExportSeparatorZod>;

/**
 * Comma-separated file extensions accepted by the entity file pickers.
 *
 * ```
 * <input type="file" accept={ENTITY_FILE_ACCEPT} />
 * ```
 */
export const ENTITY_FILE_ACCEPT = ".xlsx,.csv,.tsv,.txt,.zip";

export const generalImportModes = ["Replace", "Overwrite", "Append"] as const;
export const GeneralImportModeZod = z.enum(generalImportModes);
export type GeneralImportMode = z.infer<typeof GeneralImportModeZod>;
