import React from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import Plot, { PlotParams } from "react-plotly.js";

import Plotly from "plotly.js";
import { de, fr } from "plotly.js-locales";

import useSettings from "../../hooks/useSettings";
import {
  mapDecimalSepToChar,
  mapThousandsSepToChar,
} from "../formatter/Number";
import copyIconPath from "./copyIconPath";

const supportedLocales = [de, fr] as const;
const supportedLocaleCodes = [
  "en",
  ...supportedLocales.map((l) => l.name),
] as const;
type PlotlyLocale = (typeof supportedLocaleCodes)[number];

interface PrognosPlotProps extends PlotParams {
  id: string;
}

export default function PrognosPlot(allProps: PrognosPlotProps): JSX.Element {
  const { id, ...props } = allProps;
  const { t, i18n } = useTranslation();

  const extLocale = i18n.languages[0] as PlotlyLocale;
  const locale = supportedLocaleCodes.includes(extLocale) ? extLocale : "en";

  const handleLegendClick = useLegendClickCallback(props);

  const separators = useSeparators();

  return (
    <Plot
      divId={id}
      {...props}
      onLegendClick={handleLegendClick}
      //useResizeHandler
      layout={{ separators, ...props.layout }}
      config={{
        ...props.config,
        toImageButtonOptions: {
          ...DEFAULT_IMAGE_OPTIONS,
          ...props.config?.toImageButtonOptions,
        },
        responsive: true,
        locale,
        locales: {
          ...supportedLocales.reduce((a, v) => ({ ...a, [v.name]: v }), {}),
          ...props.config?.locales,
        },
        displaylogo: false,
        modeBarButtonsToAdd: [
          {
            name: "copy-to-clipboard",
            title: t("Copy plot png to clipboard"),
            icon: copyIconPath,
            click: async (gd) => {
              try {
                const url = await Plotly.toImage(gd, {
                  ...DEFAULT_IMAGE_OPTIONS,
                });
                const data = await fetch(url);
                const blob = await data.blob();
                await navigator.clipboard.write([
                  new ClipboardItem({
                    [blob.type]: blob,
                  }),
                ]);
                toast.success(t("The png was copied to clipboard."));
              } catch (e) {
                console.error(e);
                toast.error(t("Could not copy the png to clipboard."));
              }
            },
            gravity: "left",
          },
        ],
      }}
    />
  );
}

function useSeparators(): string {
  const { decimalSeparator, thousandsSeparator } = useSettings();

  return (
    mapDecimalSepToChar(decimalSeparator) +
    mapThousandsSepToChar(thousandsSeparator)
  );
}

type Full<T> = {
  [P in keyof T]-?: T[P];
};

export const DEFAULT_IMAGE_OPTIONS: Full<
  Pick<
    Plotly.Config["toImageButtonOptions"],
    "width" | "height" | "format" | "scale"
  >
> = {
  width: 960,
  height: 540,
  format: "png",
  scale: 2,
};

function useLegendClickCallback(
  props: PlotParams
): ((event: Readonly<Plotly.LegendClickEvent>) => boolean) | undefined {
  const { onLegendClick, onLegendDoubleClick } = props;

  const timeoutRef = React.useRef<NodeJS.Timeout>();
  React.useEffect(() => {
    return () => clearTimeout(timeoutRef.current);
  }, []);

  if (onLegendClick && onLegendDoubleClick) {
    return (e) => {
      if (e.event.detail === 1) {
        timeoutRef.current = setTimeout(() => {
          return onLegendClick(e);
        }, 150);
      }
      if (e.event.detail === 2) {
        clearTimeout(timeoutRef.current);
        return onLegendDoubleClick(e);
      }
      return false;
    };
  }

  return onLegendClick;
}
