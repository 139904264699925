import { useTranslation } from "react-i18next";

import { TimeLocaleDefinition, timeFormatDefaultLocale } from "d3-time-format";

import useSettings from "./useSettings";

export default function useD3Locale() {
  const { t } = useTranslation();
  const settings = useSettings();

  const locale: TimeLocaleDefinition = {
    dateTime: `${settings.dateFormat} ${settings.timeFormat}`,
    date: settings.dateFormat,
    time: settings.timeFormat,
    periods: ["AM", "PM"],
    days: [
      t("Sunday"),
      t("Monday"),
      t("Tuesday"),
      t("Wednesday"),
      t("Thursday"),
      t("Friday"),
      t("Saturday"),
    ],
    shortDays: [
      t("Sunday <short>"),
      t("Monday <short>"),
      t("Tuesday <short>"),
      t("Wednesday <short>"),
      t("Thursday <short>"),
      t("Friday <short>"),
      t("Saturday <short>"),
    ],
    months: [
      t("January"),
      t("February"),
      t("March"),
      t("April"),
      t("May"),
      t("June"),
      t("July"),
      t("August"),
      t("September"),
      t("October"),
      t("November"),
      t("December"),
    ],
    shortMonths: [
      t("January <short>"),
      t("February <short>"),
      t("March <short>"),
      t("April <short>"),
      t("May <short>"),
      t("June <short>"),
      t("July <short>"),
      t("August <short>"),
      t("September <short>"),
      t("October <short>"),
      t("November <short>"),
      t("December <short>"),
    ],
  };

  timeFormatDefaultLocale(locale);

  return locale;
}
