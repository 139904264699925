import React from "react";
import { useTranslation } from "react-i18next";

import { TimeLocaleDefinition, timeFormat } from "d3-time-format";
import { TFunction } from "i18next";

import { FormatterProps } from ".";
import { Aggregate } from "../../../../models/aggregate";
import { tzDateFormat } from "../../../../services/timezone";
import useD3Locale from "../../hooks/useD3Locale";
import useSettings from "../../hooks/useSettings";
import { UserSettings } from "../../models/settings";

interface DateTimeProps extends FormatterProps {
  aggregate?: Aggregate;
  timezone?: string;
}

export default function DateTime(props: DateTimeProps): JSX.Element {
  const { value, aggregate = "1D", timezone } = props;

  const { t } = useTranslation();
  const locale = useD3Locale();
  const settings = useSettings();

  if (!(value instanceof Date)) {
    return <>{value}</>;
  }

  const isoValue = !isNaN(new Date(value).getTime())
    ? value.toISOString()
    : undefined;

  const dateTime = formatDateTime(
    value,
    aggregate,
    settings,
    t,
    locale,
    timezone
  );

  return <span title={isoValue}>{dateTime}</span>;
}

export function formatDateTime(
  value: Date,
  aggregate: Aggregate,
  settings: UserSettings,
  t: TFunction,
  locale: TimeLocaleDefinition | undefined,
  timezone: string | undefined
): string {
  const format = getDateTimeFormat(aggregate, settings, t);
  const dateTime = getDateTime(value, format, timezone, locale);

  return dateTime;
}

const getDateTime = (
  date: Date,
  format: string,
  timeZone?: string,
  locale?: TimeLocaleDefinition
) => {
  if (timeZone) {
    return tzDateFormat(date, format, timeZone, locale);
  }
  return timeFormat(format)(date);
};

const getDateTimeFormat = (
  aggregate: Aggregate,
  settings: UserSettings,
  t: TFunction<"translation", undefined>
): string => {
  switch (aggregate) {
    case "1Y":
      return "%Y";
    case "1Q":
      return "Q%q %Y";
    case "1M":
      return "%B %Y";
    case "1W":
      return `${t("Week")} %V, %W`;
    case "1D":
      return settings.dateFormat;
    case "1h":
    case "30m":
    case "15m":
    case "10m":
      return `${settings.dateFormat} ${settings.timeFormat}`;
  }
};
