import React from "react";

import debounce from "debounce";

export default function useDebounce(cb: (...args: any) => void, delay: number) {
  const inputsRef = React.useRef({ cb, delay });
  const isMounted = useIsMounted();
  React.useEffect(() => {
    inputsRef.current = { cb, delay };
  });

  return React.useCallback(
    debounce((...args: any) => {
      if (inputsRef.current.delay === delay && isMounted())
        inputsRef.current.cb(...args);
    }, delay),
    [delay, debounce]
  );
}

function useIsMounted() {
  const isMountedRef = React.useRef(true);
  React.useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);
  return () => isMountedRef.current;
}
