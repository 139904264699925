import React from "react";
import { useTranslation } from "react-i18next";

import classNames from "classnames";

import Checkbox from "./Checkbox";

type DefaultOption = {
  key: string | number;
  value: string | number | boolean;
  label?: string;
  disabled?: boolean;
  forceCheck?: boolean;
};

interface CheckboxProps<Option> {
  id: string;
  label?: string;
  options: readonly Option[];
  value: Option[];
  onChange(value: Option[]): void;
  description?: string;
  size?: "medium" | "small";
  autoFocus?: boolean;
  disabled?: boolean;
  uncheckThin?: boolean;
  containerGridClassName?: string;
}

export default function ListCheckbox<Option extends DefaultOption>(
  props: CheckboxProps<Option>
) {
  const { id, label, options, value, onChange } = props;
  const { description, autoFocus, disabled, uncheckThin } = props;
  const { containerGridClassName } = props;

  const { t } = useTranslation();

  const handleInputClick = (option: Option, checked: boolean) => {
    if (checked) {
      onChange([...value, option]);
    } else {
      onChange(value.filter((v) => v.key !== option.key));
    }
  };

  return (
    <>
      {label && (
        <label className="text-base font-medium text-gray-700">{label}</label>
      )}
      {description && (
        <p className="text-sm leading-5 text-gray-500">{description}</p>
      )}
      <fieldset id={id} className={label || description ? "mt-4" : ""}>
        <legend className="sr-only">
          {label} {t("option")}
        </legend>
        <div className={classNames("grid gap-4", containerGridClassName)}>
          {options.map((option, index) => {
            const checked = value.some((v) => v.key === option.key);
            return (
              <div
                key={option.key}
                className={`flex items-center ${
                  disabled ? "" : "cursor-pointer"
                }`}
              >
                <Checkbox
                  autoFocus={autoFocus && index === 0}
                  id={`${id}-checkbox-${option.key}`}
                  name={`checkbox-${option.key}`}
                  checked={checked || (option.disabled && option.forceCheck)}
                  disabled={option.disabled || disabled}
                  onChange={(e) => handleInputClick(option, e.target.checked)}
                />
                <div
                  className={`ml-3 text-sm ${disabled ? "" : "cursor-pointer"}`}
                >
                  <label
                    htmlFor={`${id}-checkbox-${option.key}`}
                    className={classNames(
                      uncheckThin && !checked
                        ? "font-light text-gray-900"
                        : "font-medium text-gray-700",
                      !disabled ? "cursor-pointer" : ""
                    )}
                  >
                    {option.label}
                  </label>
                </div>
              </div>
            );
          })}
        </div>
      </fieldset>
    </>
  );
}
