import React from "react";

interface SideMenuProps {
  children: React.ReactNode;
}

export default function SideMenu(props: SideMenuProps): JSX.Element {
  const { children } = props;

  return (
    <aside className="py-6 px-2 sm:px-6 lg:col-span-3 lg:py-0 lg:px-0">
      <nav className="flex flex-col space-y-1">{children}</nav>
    </aside>
  );
}
