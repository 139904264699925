import React from "react";

import classNames from "classnames";

import { CalendarDaysIcon } from "@heroicons/react/24/outline";

import { changeTimeZone } from "../../services/timezone";
import FormattedDatePicker from "../FormattedDatePicker";

const MAX_DATE = new Date("2099-12-01T00:00:00.000Z");

interface DatePickerProps<T> {
  id: string;
  label?: string;
  style?: React.StyleHTMLAttributes<HTMLInputElement>;
  value: T | null;
  defaultValue?: T | null;
  minValue?: T | null;
  maxValue?: T | null;
  error?: string | boolean;
  info?: string;
  utc?: boolean;
  disabled?: boolean;
  noEmpty?: boolean;
  placeholder?: string;
  onChange(value: Date | null): void;
}

export default function CustomDatePicker<T extends string | Date>(
  props: DatePickerProps<T>
) {
  const { id, label, value, error, info, placeholder } = props;
  const { utc = false, disabled = false, noEmpty = false } = props;
  const { defaultValue = null, minValue = null, maxValue = MAX_DATE } = props;
  const { onChange } = props;

  const date = useProperDate(value, utc);
  const defaultDate = useProperDate(defaultValue, utc);
  const minDate = useProperDate(minValue, utc);
  const maxDate = useProperDate(maxValue, utc);

  const handleChange = (newDate: Date | null): void => {
    if (noEmpty && newDate === null) {
      return;
    }
    if (newDate && utc) {
      newDate = new Date(fakeUTC(newDate));
    }
    onChange(newDate);
  };

  return (
    <div>
      {label && (
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      <div id={id} className={`${label ? "mt-1" : ""} relative`}>
        <FormattedDatePicker
          selected={date}
          openToDate={!date ? (defaultDate ?? undefined) : undefined}
          minDate={minDate ?? undefined}
          maxDate={maxDate ?? undefined}
          onChange={handleChange}
          disabled={disabled}
          placeholderText={placeholder}
          // for some reason the customInput doesn't work with a separate component
          // even using proper ref forwarding
          customInput={
            <input
              type="text"
              className={classNames(
                "form-input block w-full sm:text-sm pr-8",
                "rounded border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500",
                "disabled:bg-gray-100 transition-colors duration-300",
                error
                  ? "border-red-400 focus:border-red-500 focus:ring-red-500"
                  : ""
              )}
            />
          }
        />
        <CalendarDaysIcon
          height={22}
          className="absolute top-2 right-2 text-gray-400 pointer-events-none"
        />
      </div>
      {typeof error === "string" && (
        <p className="mt-2 text-sm text-red-600">{error}</p>
      )}
      {info && (!error || error === true) && (
        <p className="mt-2 text-sm text-gray-500">{info}</p>
      )}
    </div>
  );
}

function useProperDate(
  value: string | Date | null,
  isUtc: boolean
): Date | null {
  let date = value ? new Date(value) : null;

  if (date && isNaN(date.valueOf())) {
    date = null;
  }

  if (date && isUtc) {
    date = changeTimeZone(date, "UTC");
  }

  return date;
}

export function fakeUTC(date: Date): string {
  const year = date.getFullYear();

  const month = (date.getMonth() + 1).toString().padStart(2, "0");

  const day = date.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}T00:00:00.000Z`;
}
