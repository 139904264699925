import { useQuery } from "@tanstack/react-query";

import { settingsQuery, useEditSettings } from "../../../api/user";
import { Aggregate, compareScales } from "../../../models/aggregate";
import { SeriesType } from "../models/series";
import { UserSettings, defaultUserSettings } from "../models/settings";

export default function useSettings() {
  const { data: settings } = useQuery(settingsQuery());

  return settings ?? defaultUserSettings;
}

export function useSettingsState(): [
  UserSettings,
  (patch: Partial<UserSettings>) => Promise<UserSettings>,
] {
  const settings = useSettings();
  const editSettings = useEditSettings();

  const callback = (patch: Partial<UserSettings>) => {
    return editSettings.mutateAsync(patch);
  };

  return [settings, callback];
}

export function getPreferredChartType(
  userSettings: UserSettings,
  scale: Aggregate
): SeriesType {
  if (compareScales(scale, ">=", "1D")) {
    return userSettings.defaultChartSeriesType;
  }

  return (
    userSettings.defaultSubdailySeriesType ??
    userSettings.defaultChartSeriesType
  );
}

export function usePreferredChartType(scale: Aggregate): SeriesType {
  const settings = useSettings();

  return getPreferredChartType(settings, scale);
}
