import React from "react";

export default function useShiftPressed(): boolean {
  const [shiftPressed, setShiftPressed] = React.useState(false);
  React.useEffect(() => {
    // Key down event listener
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Shift") {
        setShiftPressed(true);
      }
    };

    // Key up event listener
    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === "Shift") {
        setShiftPressed(false);
      }
    };

    // Attach event listeners
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
  }, [setShiftPressed]);

  return shiftPressed;
}
