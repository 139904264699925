import { z } from "zod";

export const UploadLinkContainerZod = z.object({
  uploadId: z.string().nullable(),
  completionRequired: z.boolean(),
  objectName: z.string(),
  chunks: z
    .object({
      url: z.string(),
      start: z.number().int().nonnegative(),
      length: z.number().int().nonnegative(),
    })
    .array(),
});
export type UploadLinkContainer = z.infer<typeof UploadLinkContainerZod>;

export const CompleteUploadZod = z.object({
  uploadId: z.string().nullable(),
  objectName: z.string(),
  parts: z
    .object({
      ETag: z.string(),
    })
    .array(),
});
export type CompleteUpload = z.infer<typeof CompleteUploadZod>;
