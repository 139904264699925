import { z } from "zod";

import { AggregateZod } from "../../../models/aggregate";
import { DateTimeZod } from "../../../models/primitives";
import { DayOfWeekZod, daysOfWeek } from "../../../models/primitives";
import { ResultZod } from "./result";
import { ChartSeriesZod } from "./series";

export const chartTypes = ["Series", "Yoy", "BoxPlot", "Histogram"] as const;
export const ChartTypeZod = z.enum(chartTypes);
export type ChartType = z.infer<typeof ChartTypeZod>;

export const ChartZod = z.object({
  chartId: z.number(),
  name: z.string(),
});

export type Chart = z.infer<typeof ChartZod>;

export const ChartDetailZod = ChartZod.extend({
  scale: AggregateZod,
  series: ChartSeriesZod.array(),
  chartType: ChartTypeZod,
  daysOfWeek: DayOfWeekZod.array().catch([...daysOfWeek]),
  fromDate: DateTimeZod.nullable().catch(null),
  toDate: DateTimeZod.nullable().catch(null),
});
export type ChartDetail = z.infer<typeof ChartDetailZod>;

export const ChartDetailDraftZod = ChartDetailZod.omit({
  chartId: true,
  series: true,
}).extend({
  chartId: z.number().optional(),
  series: ChartSeriesZod.array(),
});
export type ChartDetailDraft = z.infer<typeof ChartDetailDraftZod>;

export const ChartStatisticsTaskZod = z.object({
  taskId: z.string(),
});
export type ChartStatisticsTask = z.infer<typeof ChartStatisticsTaskZod>;

export const ChartStatisticsResultZod = ResultZod.omit({
  frozenModels: true,
  frozenRunConfig: true,
});
export type ChartStatisticsResult = z.infer<typeof ChartStatisticsResultZod>;

export const ChartUsedIdentifiersZod = ChartZod.pick({
  chartId: true,
  name: true,
});
export type ChartUsedIdentifiers = z.infer<typeof ChartUsedIdentifiersZod>;
