import React from "react";

import { CustomIcon } from "../components/sidebar/Sidebar";

const PrognosIcon: CustomIcon = (props) => {
  return (
    <svg
      {...props}
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      version="1.1"
      id="svg1549"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeWidth={1.5}
        strokeLinejoin="round"
        strokeLinecap="round"
        d="M 3.45,12.39888 6.857792,14.47601 11.235459,9.6851632 13.965474,11.360315 20.748601,4.35 h -3.947943"
        id="path2876"
      />
      <path
        strokeWidth={1.5}
        strokeLinejoin="round"
        strokeLinecap="round"
        d="M 2.75,19.75 H 21.303197"
        id="path2880"
      />
      <path
        strokeWidth={1.5}
        strokeLinejoin="round"
        strokeLinecap="round"
        d="M 20.761056,4.3798408 V 8.1218621"
        id="path3704"
      />
    </svg>
  );
};

export default PrognosIcon;
