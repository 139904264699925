import React from "react";
import { useTranslation } from "react-i18next";

import { useQueryClient } from "@tanstack/react-query";

import { influencingFactorsQuery } from "../api/influencingFactors";
import { influencingFactorsListQuery } from "../api/influencingFactors";
import { activeOutlierDetectionTasksQuery } from "../api/tasks";
import { Message } from "../models/message";
import { GeneralSolutionTaskZod } from "../models/task";

export default function useOutlierDetectionMessageCallback() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const callback = React.useCallback(
    (msg: Message) => {
      if (
        msg.eventName !== "TaskUpdate" ||
        msg.eventArgs.taskName !== "OutlierDetection"
      ) {
        return;
      }

      const task = GeneralSolutionTaskZod.parse(msg.eventArgs);
      const solutionId = task.solutionId.toString();

      const continuousKeysToInvalidate = [
        activeOutlierDetectionTasksQuery(solutionId),
        influencingFactorsQuery(solutionId),
        influencingFactorsListQuery(solutionId),
      ];

      for (const query of continuousKeysToInvalidate) {
        queryClient.invalidateQueries(query);
      }
    },
    [queryClient, t]
  );

  return callback;
}
