import { z } from "zod";

import { DateTimeZod } from "../../../models/primitives";
import { AlgorithmConfigZod } from "./algorithmConfig";

export const ModelZod = z.object({
  modelId: z.number(),
  name: z.string(),
  version: z.string(),
  algorithmConfigs: AlgorithmConfigZod.array(),
  lastExecuted: DateTimeZod.nullable().optional(),
});
export type Model = z.infer<typeof ModelZod>;

export const ModelDetailZod = ModelZod.extend({
  algorithmConfigs: AlgorithmConfigZod.array(),
  description: z.string().nullable().optional(),
});
export type ModelDetail = z.infer<typeof ModelDetailZod>;

export const ModelUsedIdentifiersZod = ModelZod.pick({
  modelId: true,
  name: true,
  version: true,
});
export type ModelUsedIdentifiers = z.infer<typeof ModelUsedIdentifiersZod>;
