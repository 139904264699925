import React from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { useQueries } from "@tanstack/react-query";

import { EndpointOptions } from "../../../../../api";
import { useSolution } from "../../../../../api/solutions";
import SwitchBanner from "../../../../../components/SwitchBanner";
import { DEFAULT_PAGE_SIZE } from "../../../../../services/constants";
import { resultSegmentsQuery } from "../../../api/results";
import { useAppliedEndpointOptions } from "../../../hooks/useEndpoint";
import useEndpointOptions from "../../../hooks/useEndpoint/useEndpointOptions";
import { ResultSegment } from "../../../models/result";
import { getResultDetailPath } from "../../../routes/results";
import { DEFAULT_SEGMENT_OPTIONS, SEGMENT_ENDP_KEY } from "./SegmentsTable";

interface SegmentsSwitchProps {
  segment: ResultSegment | undefined;
}

export default function SegmentsSwitch(
  props: SegmentsSwitchProps
): JSX.Element {
  const { segment } = props;

  const links = useLinks(segment);

  return (
    <SwitchBanner
      {...links}
      isLoading={!segment}
      paddings
      filterLink={
        links.options.filter?.length
          ? `..${window.location.search}#segmentsCard`
          : undefined
      }
    >
      {segment?.partitionFullName}{" "}
      {segment?.measurementName && (
        <span className="font-normal text-gray-400">
          [{segment.measurementName}]
        </span>
      )}
    </SwitchBanner>
  );
}

function useLinks(segment: ResultSegment | undefined): {
  previousLink: string;
  nextLink: string;
  index?: number;
  count?: number;
  options: EndpointOptions;
} {
  const { runResultId } = useParams();
  if (!runResultId) {
    throw new Error("URL param :runResultId not specified!");
  }

  const [, solutionId] = useSolution();

  const [searchParams] = useSearchParams();

  const [lastCount, setLastCount] = React.useState<number | null>(null);

  const { options } = useEndpointOptions(
    SEGMENT_ENDP_KEY,
    DEFAULT_SEGMENT_OPTIONS
  );
  const pageSize = options.pageSize ?? DEFAULT_PAGE_SIZE;

  const [page, pages] = usePreloadedSwitchPages(lastCount, pageSize);

  const appliedOptions = useAppliedEndpointOptions(options, null);

  const pagesData = useQueries({
    queries: pages.map((p) =>
      resultSegmentsQuery(runResultId, {
        ...appliedOptions,
        page: p > 1 ? p : undefined,
      })
    ),
  });

  const count = pagesData[0].data?.count ?? null;
  React.useEffect(() => {
    if (count !== lastCount) {
      setLastCount(count);
    }
  }, [count, setLastCount]);

  const allSegments = pagesData.flatMap(
    (pd, index) =>
      pd.data?.items.map((item) => ({ item, page: pages[index] })) ?? []
  );

  const currentIndex = allSegments.findIndex(
    (seg) => seg.item.dataSegmentResultId === segment?.dataSegmentResultId
  );

  if (currentIndex === -1) {
    return { previousLink: "", nextLink: "", options: appliedOptions };
  }

  const previousSegment =
    currentIndex > 0 ? allSegments.at(currentIndex - 1) : undefined;
  const nextSegment =
    currentIndex < allSegments.length
      ? allSegments.at(currentIndex + 1)
      : undefined;

  let previousLink = "";
  if (previousSegment && previousSegment !== allSegments.at(currentIndex)) {
    searchParams.set("Page", previousSegment.page.toString());
    previousLink = `${getResultDetailPath(solutionId, runResultId)}/${
      previousSegment.item.dataSegmentResultId
    }?${searchParams.toString()}${window.location.hash}`;
  }
  let nextLink = "";
  if (nextSegment && nextSegment !== allSegments.at(currentIndex)) {
    searchParams.set("Page", nextSegment.page.toString());
    nextLink = `${getResultDetailPath(solutionId, runResultId)}/${
      nextSegment.item.dataSegmentResultId
    }?${searchParams.toString()}${window.location.hash}`;
  }

  return {
    previousLink,
    nextLink,
    index: (page - 1) * pageSize + ((currentIndex % pageSize) + 1),
    count: count ?? undefined,
    options: appliedOptions,
  };
}

export function usePreloadedSwitchPages(
  lastCount: number | null,
  pageSize: number
): [number, number[]] {
  const [originalSearch] = useSearchParams();
  let page = parseInt(originalSearch.get("Page") ?? "1");
  if (isNaN(page) || page < 1) {
    page = 1;
  }
  if (lastCount !== null && page > 1 && pageSize) {
    const firstItemIdx = (page - 1) * pageSize;
    if (firstItemIdx >= lastCount) {
      page = Math.ceil(lastCount / pageSize);
    }
  }

  const pages = [page, page + 1];
  if (page > 1) {
    pages.unshift(page - 1);
  }

  return [page, pages];
}
