import { z } from "zod";

import { parseOrderBy, stringifyOrderBy } from "../../../../api";
import { SortSetting } from "../../../../models/primitives";
import useLocalStorage from "../useLocalStorage";
import { EndpointKey } from "./useEndpointOptions";

export default function useEndpointSort(
  endpointKey: EndpointKey
): [SortSetting[] | undefined, (sort: SortSetting[]) => void] {
  const [orderBy, setOrderBy] = useLocalStorage(
    `EndpointSort-${endpointKey}`,
    undefined,
    z.string().optional()
  );

  let value: SortSetting[] | undefined = undefined;
  if (orderBy) {
    const parsedSort = parseOrderBy(orderBy);
    value = parsedSort.length > 0 ? parsedSort : undefined;
  }

  const onChange = (sort: SortSetting[]) => setOrderBy(stringifyOrderBy(sort));

  return [value, onChange];
}
