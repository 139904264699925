import React from "react";
import { useTranslation } from "react-i18next";

import classNames from "classnames";

import SelectComponent, {
  BaseSelectComponentProps,
  SelectOption,
} from "./SelectComponent";

interface SelectProps<Option>
  extends Omit<
    BaseSelectComponentProps<Option>,
    "keyProp" | "buttonText" | "getOptionLabel"
  > {
  value: Option | null;
  onChange(value: Option): void;
  keyProp?: keyof Option & string;
  labelProp?: keyof Option & string;
  placeholderLabel?: string;
  display?: "block" | "inline-block";
  allowNone?: boolean;
}

export default function Select<Option extends SelectOption>(
  props: SelectProps<Option>
): JSX.Element {
  const { options, value, display } = props;
  const { onChange } = props;
  const { keyProp = "key", labelProp = "label" } = props;
  const { allowNone = false } = props;

  const { t } = useTranslation();
  const { placeholderLabel = t("select") } = props;

  const getOptionLabel = (option: Option | null): string | null => {
    if (option === null) {
      return null;
    }
    const val = option[labelProp] ?? option[keyProp];
    return typeof val === "string" ? val : null;
  };

  const displayedValue = getOptionLabel(value) ?? `-- ${placeholderLabel} --`;

  const usedOptions = allowNone
    ? [
        { [keyProp]: null, [labelProp]: `-- ${placeholderLabel} --` } as Option,
        ...options,
      ]
    : options;

  return (
    <SelectComponent
      {...props}
      className={classNames(
        props.className,
        allowNone && value === null ? "text-gray-400" : ""
      )}
      buttonText={displayedValue}
      keyProp={keyProp}
      options={usedOptions}
      containerClassName={display}
      getOptionLabel={(opt) => getOptionLabel(opt)}
      multiple={false}
      value={value}
      onChange={onChange}
    />
  );
}
