import React from "react";

export type ComparedResult = {
  runResultId: number;
  runConfigId: number;
  runConfigName: string;
};

type SolutionResultComparisonData = {
  solutionId: string;
  runConfigId: number;
  runConfigName: string;
  results: ComparedResult[];
};

enum GlobalActionKind {
  TOGGLE_SIDEBAR,
  ADD_RESULT,
  REMOVE_RESULT,
  CLEAR_COMPARISON,
}

type GlobalAction =
  | {
      type: GlobalActionKind.TOGGLE_SIDEBAR;
      payload: null;
    }
  | {
      type: GlobalActionKind.ADD_RESULT;
      payload: { solutionId: string; result: ComparedResult };
    }
  | {
      type: GlobalActionKind.REMOVE_RESULT;
      payload: { solutionId: string; runResultId: number };
    }
  | {
      type: GlobalActionKind.CLEAR_COMPARISON;
      payload: { solutionId: string };
    };

export type GlobalState = {
  sidebar: {
    collapsed: boolean;
  };
  resultComparison: {
    solutions: SolutionResultComparisonData[];
  };
};

const GlobalStateContext = React.createContext<
  [GlobalState, React.Dispatch<GlobalAction>] | null
>(null);

const getNewSolutionComparisonData = (data: {
  solutionId: string;
  result: ComparedResult;
}): SolutionResultComparisonData => {
  return {
    solutionId: data.solutionId,
    runConfigId: data.result.runConfigId,
    runConfigName: data.result.runConfigName,
    results: [],
  };
};

function reducer(state: GlobalState, action: GlobalAction): GlobalState {
  const { type, payload } = action;
  switch (type) {
    case GlobalActionKind.TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebar: { ...state.sidebar, collapsed: !state.sidebar.collapsed },
      };
    case GlobalActionKind.ADD_RESULT: {
      const { solutionId, result } = payload;
      const solutions = state.resultComparison.solutions;
      const solution =
        solutions.find((s) => s.solutionId === solutionId) ??
        getNewSolutionComparisonData(payload);

      if (solution.results.some((r) => r.runResultId === result.runResultId)) {
        return state;
      }

      if (solution.runConfigId !== result.runConfigId) {
        return state;
      }

      solution.results.push(result);
      const newSolutions = solutions
        .filter((s) => s.solutionId !== solution.solutionId)
        .concat([solution]);

      return {
        ...state,
        resultComparison: {
          ...state.resultComparison,
          solutions: newSolutions,
        },
      };
    }
    case GlobalActionKind.REMOVE_RESULT: {
      const { solutionId, runResultId } = payload;
      const solutions = state.resultComparison.solutions;
      const solution = solutions.find((s) => s.solutionId === solutionId);

      if (!solution) {
        return state;
      }

      solution.results = solution.results.filter(
        (r) => r.runResultId !== runResultId
      );
      const newSolutions = solutions
        .filter((s) => s.solutionId !== solution.solutionId)
        .concat(solution.results.length > 0 ? [solution] : []);

      return {
        ...state,
        resultComparison: {
          ...state.resultComparison,
          solutions: newSolutions,
        },
      };
    }
    case GlobalActionKind.CLEAR_COMPARISON: {
      const { solutionId } = payload;
      return {
        ...state,
        resultComparison: {
          ...state.resultComparison,
          solutions: state.resultComparison.solutions.filter(
            (s) => s.solutionId !== solutionId
          ),
        },
      };
    }
  }
}

interface GlobalStateProviderProps {
  children: React.ReactNode;
}

export function GlobalStateProvider(
  props: GlobalStateProviderProps
): JSX.Element {
  const { children } = props;

  const [state, dispatch] = React.useReducer(reducer, {
    sidebar: { collapsed: false },
    resultComparison: { solutions: [] },
  });

  return (
    <GlobalStateContext.Provider value={[state, dispatch]}>
      {children}
    </GlobalStateContext.Provider>
  );
}

export function useGlobalState(): [GlobalState, React.Dispatch<GlobalAction>] {
  const context = React.useContext(GlobalStateContext);
  if (!context) {
    throw new Error("GlobalState not provided.");
  }

  return context;
}

export function toggleSidebar(): GlobalAction {
  return {
    type: GlobalActionKind.TOGGLE_SIDEBAR,
    payload: null,
  };
}

export function addComparedResult(
  solutionId: string,
  result: ComparedResult
): GlobalAction {
  return {
    type: GlobalActionKind.ADD_RESULT,
    payload: { solutionId, result },
  };
}

export function removeComparedResult(
  solutionId: string,
  runResultId: number
): GlobalAction {
  return {
    type: GlobalActionKind.REMOVE_RESULT,
    payload: { solutionId, runResultId },
  };
}

export function clearResultComparison(solutionId: string): GlobalAction {
  return {
    type: GlobalActionKind.CLEAR_COMPARISON,
    payload: { solutionId },
  };
}
