import React from "react";
import * as ReactDOMClient from "react-dom/client";

import AppSuite from "./AppSuite";
import "./services/i18n";
import "./styles/main.css";

const container = document.getElementById("app");

if (container !== null) {
  const root = ReactDOMClient.createRoot(container);
  root.render(<AppSuite />);
}
