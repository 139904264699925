import React from "react";

import classNames from "classnames";

import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";

import Button, { ButtonProps } from "../../../../../components/buttons/Button";

interface StandardExportButtonProps {
  id: string;
  className?: string;
  children: React.ReactNode;
  onClick(): void;
  disabled?: boolean;
  variant?: ButtonProps["variant"];
  hideIcon?: boolean;
  title?: string;
}

export default function StandardExportButton(
  props: StandardExportButtonProps
): JSX.Element {
  const { id, children, className, disabled, variant = "white" } = props;
  const { hideIcon, title } = props;
  const { onClick } = props;

  return (
    <Button
      id={id}
      onClick={onClick}
      variant={variant}
      className={classNames("", className)}
      disabled={disabled}
      title={title}
    >
      {!hideIcon && (
        <ArrowDownTrayIcon className="w-4 h-4 -ml-1 mr-2 text-gray-500" />
      )}
      {children}
    </Button>
  );
}
