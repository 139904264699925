import React from "react";

import SegmentsSwitch from "../../apps/PrognosAI/pages/Result/components/SegmentsSwitch";
import Card from "../Card";
import SkeletonCard from "./SkeletonCard";
import SkeletonChart from "./SkeletonChart";
import SkeletonHero from "./SkeletonHero";
import SkeletonWrapper from "./SkeletonWrapper";

interface ChartSkeletonProps {
  withSwitch?: boolean;
}

export default function ChartSkeleton(props: ChartSkeletonProps): JSX.Element {
  const { withSwitch = false } = props;

  return (
    <SkeletonWrapper>
      <SkeletonHero />
      <div className="space-y-4">
        {withSwitch && <SegmentsSwitch segment={undefined} />}
        <div className="w-full px-3">
          <div className="bg-gray-200 w-full rounded h-9 py-2 pl-3 pr-10"></div>
        </div>
        <SkeletonCard
          style={{
            height: withSwitch
              ? "calc(100vh - 148px - 88px - 36px - 16px - 28px - 16px)"
              : "calc(100vh - 148px - 88px - 36px - 16px)",
          }}
        >
          <div className="w-full max-w-[360px]">
            <SkeletonChart />
          </div>
        </SkeletonCard>
        <Card className="border-2 border-gray-200 bg-transparent shadow-none flex items-center justify-between h-[4.5rem] mt-4">
          <div className="flex space-x-2">
            <div className="h-7 bg-gray-200 rounded-full w-32"></div>
            <div className="h-7 bg-gray-200 rounded-full w-32"></div>
          </div>
          <div className="flex space-x-2">
            <div className="h-7 bg-gray-200  rounded-full w-32 hidden md:block"></div>
            <div className="h-7 border-2 border-gray-200 rounded-full w-48 hidden md:block"></div>
            <div className="h-7 bg-gray-200 rounded-full w-8"></div>
          </div>
        </Card>
      </div>
    </SkeletonWrapper>
  );
}
