import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { EndpointOptions } from "../../../api";
import api, { getQueryString } from "../../../api";
import { Aggregate } from "../../../models/aggregate";
import { addNullPoints } from "../functions/aggregate";
import { PaginatedResponse, paginatedResponse } from "../models/response";
import {
  ComparedResultSegment,
  ComparedResultSegmentZod,
  IntradayProfileSet,
  IntradayProfileSetZod,
  Result,
  ResultContributionEntry,
  ResultContributionEntryZod,
  ResultDataEntry,
  ResultDataEntryZod,
  ResultDetail,
  ResultDetailZod,
  ResultProblems,
  ResultProblemsZod,
  ResultSegment,
  ResultSegmentSparkline,
  ResultSegmentSparklineZod,
  ResultSegmentZod,
  ResultZod,
  SegmentInfluencingFactor,
  SegmentInfluencingFactorZod,
  SegmentResultObject,
  SegmentResultObjectZod,
} from "../models/result";
import { TaskDiagnostic, TaskDiagnosticZod, TaskNameZod } from "../models/task";

const RESULTS_API = "/Prognos/Results";

async function getResults(
  solutionId: string,
  options: EndpointOptions = {}
): Promise<PaginatedResponse<Result[]>> {
  const query = getQueryString({
    ...options,
    filter: [
      ...(options.filter ?? []),
      [
        "taskName",
        "=",
        [TaskNameZod.Enum.RunModel, TaskNameZod.Enum.RunMultipleModels],
      ],
    ],
  });
  const resultsQ = paginatedResponse(ResultZod.array()).parse(
    (await api.get(`/Prognos/Solutions/${solutionId}/Results?${query}`)).data
  );
  return resultsQ;
}

export const resultsQuery = (
  solutionId: string,
  options?: EndpointOptions
) => ({
  queryKey: ["results", solutionId, ...(options ? [options] : [])],
  queryFn: () => getResults(solutionId, options),
  placeholderData: (
    previousData: PaginatedResponse<Result[]> | undefined,
    previousQuery: { queryKey: (string | EndpointOptions)[] } | undefined
  ) => (previousQuery?.queryKey[1] === solutionId ? previousData : undefined),
});

async function getResult(runResultId: string | number): Promise<ResultDetail> {
  return ResultDetailZod.parse(
    (await api.get(`${RESULTS_API}/${runResultId}`)).data
  );
}

export const resultQuery = (runResultId: string | number) => ({
  queryKey: ["result", runResultId.toString()],
  queryFn: async () => await getResult(runResultId),
});

async function updateResult(
  runResultId: string,
  patch: Partial<ResultDetail>
): Promise<ResultDetail> {
  return ResultDetailZod.parse(
    (await api.patch(`${RESULTS_API}/${runResultId}`, patch)).data
  );
}

export const useUpdateResult = (solutionId: string, runResultId: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (patch: Partial<ResultDetail>) =>
      updateResult(runResultId, patch),
    onSuccess: () => {
      queryClient.invalidateQueries(resultQuery(runResultId));
      queryClient.invalidateQueries(resultsQuery(solutionId));
    },
    onError: () => {
      toast.error(t("An error occurred while updating. Please try again."));
    },
  });
};

async function getResultSegments(
  runResultId: string,
  options: EndpointOptions = {}
): Promise<PaginatedResponse<ResultSegment[]>> {
  const query = getQueryString(options);
  return paginatedResponse(ResultSegmentZod.array()).parse(
    (await api.get(`${RESULTS_API}/${runResultId}/DataSegmentResults?${query}`))
      .data
  );
}

export const resultSegmentsQuery = (
  runResultId: string,
  options?: EndpointOptions
) => ({
  queryKey: ["resultSegments", runResultId, ...(options ? [options] : [])],
  queryFn: () => getResultSegments(runResultId, options),
});

async function getResultSegmentSparklines(
  runResultId: string,
  options: EndpointOptions = {}
): Promise<PaginatedResponse<ResultSegmentSparkline[]>> {
  const query = getQueryString(options);
  return paginatedResponse(ResultSegmentSparklineZod.array()).parse(
    (await api.get(`${RESULTS_API}/${runResultId}/Sparklines?${query}`)).data
  );
}

export const resultSegmentSparklinesQuery = (
  runResultId: string | undefined,
  options?: EndpointOptions
) => ({
  queryKey: [
    "resultSegmentSparklines",
    runResultId,
    ...(options ? [options] : []),
  ],
  queryFn: () => getResultSegmentSparklines(runResultId ?? "", options),
  enabled: !!runResultId,
});

async function getResultSegment(
  dataSegmentResultId: string
): Promise<ResultSegment> {
  return ResultSegmentZod.parse(
    (await api.get(`/DataSegmentResults/${dataSegmentResultId}`)).data
  );
}

export const resultSegmentQuery = (dataSegmentResultId: string) => ({
  queryKey: ["resultSegment", dataSegmentResultId],
  queryFn: () => getResultSegment(dataSegmentResultId),
});

async function getSegmentResultData(
  dataSegmentResultId: string,
  scale: Aggregate
): Promise<ResultDataEntry[]> {
  const response = await api.get(
    `/DataSegmentResults/${dataSegmentResultId}/ResultData?timeScale=${scale}`
  );
  const data = ResultDataEntryZod.array().parse(response.data);

  return addNullPoints(data, "ts", "value", scale);
}

export const segmentResultDataQuery = (
  dataSegmentResultId: string | undefined,
  scale: Aggregate | undefined
) => ({
  queryKey: ["segmentResultData", dataSegmentResultId, scale],
  queryFn: () =>
    scale && dataSegmentResultId
      ? getSegmentResultData(dataSegmentResultId, scale)
      : undefined,
  enabled: !!scale && !!dataSegmentResultId,
});

async function getSegmentInfluencingFactors(
  dataSegmentResultId: string
): Promise<SegmentInfluencingFactor[]> {
  return SegmentInfluencingFactorZod.array().parse(
    (
      await api.get(
        `/DataSegmentResults/${dataSegmentResultId}/InfluencingFactorResults`
      )
    ).data
  );
}

export const segmentInfluencingFactorsQuery = (
  dataSegmentResultId: string
) => ({
  queryKey: ["segmentInfluencingFactors", dataSegmentResultId],
  queryFn: () => getSegmentInfluencingFactors(dataSegmentResultId),
});

async function getSegmentResultObjects(
  dataSegmentResultId: string
): Promise<SegmentResultObject[]> {
  return SegmentResultObjectZod.array().parse(
    (await api.get(`/DataSegmentResults/${dataSegmentResultId}/ResultObjects`))
      .data
  );
}

export const segmentResultObjectsQuery = (dataSegmentResultId: string) => ({
  queryKey: ["segmentResultObjects", dataSegmentResultId],
  queryFn: () => getSegmentResultObjects(dataSegmentResultId),
});

async function getSegmentResultExplanations(
  dataSegmentResultId: string
): Promise<ResultContributionEntry[]> {
  return ResultContributionEntryZod.array().parse(
    (await api.get(`/DataSegmentResults/${dataSegmentResultId}/Explanations`))
      .data
  );
}

export const segmentResultExplanationsQuery = (
  dataSegmentResultId: string
) => ({
  queryKey: ["segmentResultExplanations", dataSegmentResultId],
  queryFn: () => getSegmentResultExplanations(dataSegmentResultId),
});

async function getIntradayProfiles(
  dataSegmentResultId: string
): Promise<IntradayProfileSet[]> {
  return IntradayProfileSetZod.array().parse(
    (
      await api.get(
        `/DataSegmentResults/${dataSegmentResultId}/IntradayProfileSets`
      )
    ).data
  );
}

export const intradayProfilesQuery = (dataSegmentResultId: string) => ({
  queryKey: ["intradayProfiles", dataSegmentResultId],
  queryFn: () => getIntradayProfiles(dataSegmentResultId),
});

async function deleteResult(runResultId: string) {
  return api.delete(`${RESULTS_API}/${runResultId}`);
}

export const useDeleteResult = (
  solutionId: string,
  silent = false,
  redirect = ""
) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (runResultId: Result["runResultId"]) =>
      deleteResult(runResultId.toString()),
    onSuccess: (_, runResultId) => {
      if (!silent) {
        toast.success(t("Result deleted successfully."));
      }
      queryClient.invalidateQueries(resultsQuery(solutionId));
      queryClient.removeQueries(resultQuery(runResultId));
      if (redirect) {
        navigate(redirect);
      }
    },
    onError: () => {
      if (!silent) {
        toast.error(t("An error occurred while deleting. Please try again."));
      }
    },
  });
};

async function deleteResultsByIds(ids: number[]) {
  return api.delete(`${RESULTS_API}/Batch`, { data: { ids } });
}

export const useDeleteResultsByIds = (solutionId: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: deleteResultsByIds,
    onSuccess: (_, ids) => {
      toast.success(t("Results deleted successfully."));
      queryClient.invalidateQueries(resultsQuery(solutionId));
      queryClient.removeQueries(...ids.map((id) => resultQuery(id)));
    },
    onError: () => {
      toast.error(t("An error occurred while deleting. Please try again."));
    },
  });
};

async function deleteResultsByFilter(
  solutionId: string,
  options: EndpointOptions
) {
  const query = getQueryString(options);
  return api.delete(`/Prognos/Solutions/${solutionId}/Results?${query}`);
}

export const useDeleteResultsByFilter = (solutionId: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (options: EndpointOptions) =>
      deleteResultsByFilter(solutionId, options),
    onSuccess: () => {
      toast.success(t("Results deleted successfully."));
      queryClient.invalidateQueries(resultsQuery(solutionId));
    },
    onError: () => {
      toast.error(t("An error occurred while deleting. Please try again."));
    },
  });
};

export const chartResultStatisticsQuery = (
  runResultId: string,
  { measurementId, partitionId }: { measurementId: number; partitionId: number }
) => ({
  queryKey: ["resultChartStatistics", runResultId, measurementId, partitionId],
  queryFn: async () => {
    const segments = await getResultSegments(runResultId);
    const segment = segments.items.find(
      (seg) =>
        seg.measurementId === measurementId && seg.partitionId === partitionId
    );
    if (!segment) {
      return [];
    }

    return getSegmentResultObjects(segment.dataSegmentResultId.toString());
  },
});

async function getResultSegmentsComparison(
  solutionId: string | number,
  runResultIds: (string | number)[],
  options: EndpointOptions = {}
): Promise<PaginatedResponse<ComparedResultSegment[]>> {
  const query = getQueryString(options);
  return paginatedResponse(ComparedResultSegmentZod.array()).parse(
    (
      await api.get(
        `/Solutions/${solutionId}/SegmentComparison?runResultIds=${runResultIds.join(
          ","
        )}&${query}`
      )
    ).data
  );
}

export const resultSegmentsComparisonQuery = (
  solutionId: string | number,
  runResultIds: (string | number)[],
  options?: EndpointOptions
) => ({
  queryKey: [
    "resultSegmentsComparison",
    solutionId.toString(),
    runResultIds.map((id) => id.toString()),
    ...(options ? [options] : []),
  ],
  queryFn: () => getResultSegmentsComparison(solutionId, runResultIds, options),
});

async function getSolutionSegments(
  solutionId: string | number,
  options: EndpointOptions = {}
): Promise<PaginatedResponse<ResultSegment[]>> {
  const query = getQueryString(options);
  return paginatedResponse(ResultSegmentZod.array()).parse(
    (await api.get(`/Solutions/${solutionId}/DataSegmentResults?${query}`)).data
  );
}

export const solutionSegmentsQuery = (
  solutionId: string | number,
  options?: EndpointOptions
) => ({
  queryKey: [
    "solutionSegments",
    solutionId.toString(),
    ...(options ? [options] : []),
  ],
  queryFn: () => getSolutionSegments(solutionId, options),
});

async function getResultProblems(runResultId: string): Promise<ResultProblems> {
  return ResultProblemsZod.parse(
    (await api.get(`/Prognos/Results/${runResultId}/Problems`)).data
  );
}

export const resultProblemsQuery = (runResultId: string) => ({
  queryKey: ["resultProblems", runResultId],
  queryFn: async () => await getResultProblems(runResultId),
});

async function getResultSegmentProblems(
  dataSegmentResultId: string
): Promise<TaskDiagnostic[]> {
  return TaskDiagnosticZod.array().parse(
    (await api.get(`/DataSegmentResults/${dataSegmentResultId}/Problems`)).data
  );
}

export const resultSegmentProblemsQuery = (dataSegmentResultId: string) => ({
  queryKey: ["resultSegmentProblems", dataSegmentResultId],
  queryFn: async () => await getResultSegmentProblems(dataSegmentResultId),
});

export const lastSegmentForecastQuery = (
  solutionId: string,
  partitionId: number | null,
  measurementId: number | null
) => ({
  enabled: !!partitionId && !!measurementId,
  queryKey: ["lastSegmentResult", solutionId, partitionId, measurementId],
  queryFn: async () => {
    if (!measurementId || !partitionId) {
      return null;
    }

    const { items: resultItems } = await getResults(solutionId, {
      filter: [
        ["partitions.partitionId", "=", partitionId],
        ["measurements.measurementId", "=", measurementId],
        ["status", "=", "Finished"],
        ["finished", "!=", ""],
        ["runConfig.taskType", "=", "Forecast"],
      ],
      sort: [["finished", "desc"]],
      pageSize: 1,
    });

    const result = resultItems.at(0);
    if (!result) {
      return null;
    }

    const { items: segmentItems } = await getResultSegmentSparklines(
      result.runResultId.toString(),
      {
        filter: [
          ["partitionId", "=", partitionId],
          ["measurementId", "=", measurementId],
        ],
      }
    );

    const segment = segmentItems.at(0);

    return { result, segment };
  },
});
