import React from "react";

import { useQuery } from "@tanstack/react-query";

import { accountQuery } from "../api/user";
import { Account } from "../models/auth";

type Session = Account & { isLoading: boolean };

const loadingSession: Session = {
  isLoading: true,
  isAuthenticated: false,
  user: null,
};

const AuthContext = React.createContext<Session>(loadingSession);

export function useAccount(): Account | null {
  const { data: account } = useQuery(accountQuery());
  return account ?? null;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

export default function AuthProvider(props: AuthProviderProps): JSX.Element {
  const { children } = props;

  const account = useAccount();

  const session: Session =
    account === null ? loadingSession : { ...account, isLoading: false };

  return (
    <AuthContext.Provider value={session}>{children}</AuthContext.Provider>
  );
}

export function useAuth(): Session {
  return React.useContext(AuthContext);
}
